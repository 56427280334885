export async function registerServiceWorkerAndSaveSubscription() {
    if ("serviceWorker" in navigator && "Notification" in window) {
      // Check notification permission
      if (Notification.permission === "default") {
        // Ask for permission
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          console.warn("Notifications are blocked by the user.");
          return; // Exit if the user blocks notifications
        }
      } else if (Notification.permission === "denied") {
        console.warn("Notifications are blocked by the user.");
        return; // Exit if notifications are already denied
      }
  
      // Register the Service Worker and handle PushManager subscription
      try {
        const registration = await navigator.serviceWorker.register("/service-worker.js");
  
        if (!("PushManager" in window)) {
          console.error("Push notifications are not supported by this browser.");
          return;
        }
  
        // Subscribe the user
        const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        if (!vapidPublicKey) {
          console.error("VAPID public key is not defined.");
          return;
        }
  
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
        });
  
  
        // Save the subscription to the backend
        const response = await fetch("https://firstfltb.com/api3/saveSubscription.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscription),
        });
  
        const result = await response.json();
        if (result.status === "success") {
        } else {
          console.error("Failed to save subscription:", result.message);
        }
      } catch (error) {
        console.error("Service Worker registration or subscription failed:", error);
      }
    } else {
      console.warn("Service Worker or Notifications are not supported in this browser.");
    }
  }
  
  // Helper to convert VAPID public key to Uint8Array
  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  