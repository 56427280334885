// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import AppWrapper from './context/AppWrapper';

const container = document.getElementById('root');
const root = createRoot(container); // Create root

root.render(
  <React.StrictMode>
  <AppWrapper>
    <App />
  </AppWrapper>,  </React.StrictMode>
);
