import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, set, get } from 'firebase/database'; // Import 'get' for fetching role
import { signInWithEmailAndPassword, auth, database } from '../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminLogin.css';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error('Please fill in all fields');
      return;
    }

    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user has a role in the database
      const userRef = ref(database, `users/${user.uid}`);
      const snapshot = await get(userRef);

      if (!snapshot.exists()) {
        // If no role exists, assign "admin" role for the first hardcoded user
        if (user.email === 'skillz@bzzinc.com') {
          await setUserRole(user.uid, user.email, 'admin');
          toast.success('Welcome, Admin!');
          navigate('/admin/dashboard');
        } else {
          // If the user is not authorized
          toast.error('You are not authorized to access this page.');
          await auth.signOut();
        }
      } else {
        // If the role exists, check the user's role
        const userData = snapshot.val();
        if (userData.role === "admin") {
          toast.success("Welcome, Admin!");
          navigate("/admin/dashboard");
        } else if (userData.role === "cashier") {
          toast.success("Welcome, Cashier!");
          navigate("/admin/transactions");
        } else {
          toast.error("You are not authorized to access this page.");
          await auth.signOut();
        }
        
      }
    } catch (error) {
      toast.error(error.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  // Set the user's role in the database
  const setUserRole = async (uid, email, role) => {
    const userRef = ref(database, `users/${uid}`);
    await set(userRef, {
      email,
      role,
    });
  };

  return (
    <div className="admin-login-container">
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <div className="admin-input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="admin-input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
