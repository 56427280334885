import React from 'react';
import './Home.css';
import Hero from './Hero';

const Home = () => {
  const handleOrderNowScroll = () => {
    document.querySelector('.hero-container').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1 className="home-brand-name">Khadz Burger</h1>
        <div className="hero-content">
          <p className="tagline">A bite into happiness</p>
        </div>
        <button className="order-now-btn" onClick={handleOrderNowScroll}>
          Order Now
        </button>
      </section>

      {/* Hero Section Below */}
      <Hero />
    </div>
  );
};

export default Home;
