import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { database, ref, set, onValue, update } from '../firebase';
import './AdminProducts.css';

const AdminProducts = ({ categories }) => {
  const [activeTab, setActiveTab] = useState('addProduct');
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [products, setProducts] = useState([]);

  // Fetch products from Firebase
  useEffect(() => {
    const productsRef = ref(database, 'products');
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProducts(Object.values(data));
      }
    });
  }, []);

  // Add product
  const handleAddProduct = async () => {
    if (!productName || !productPrice || !productCategory || !productImage) {
      alert('Please fill all fields');
      return;
    }

    setUploading(true);

    try {
      const sku = `${productCategory}-${uuidv4().slice(0, 8)}`;
      const imageUrl = await uploadImageToPinataBackend(productImage);

      const newProduct = {
        id: uuidv4(),
        name: productName,
        price: parseFloat(productPrice),
        category: productCategory,
        sku,
        image: imageUrl,
        available: true, // Default availability
      };

      const productRef = ref(database, `products/${newProduct.id}`);
      await set(productRef, newProduct);

      alert('Product added successfully!');
      setProductName('');
      setProductPrice('');
      setProductCategory('');
      setProductImage(null);
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Toggle product availability
  const toggleAvailability = async (productId, currentStatus) => {
    const productRef = ref(database, `products/${productId}`);
    try {
      await update(productRef, { available: !currentStatus });
      alert('Product availability updated!');
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Failed to update product availability.');
    }
  };

  // Upload image to Pinata using the backend API
  const uploadImageToPinataBackend = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch('https://firstfltb.com/api3/AddProduct.php', {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
  
      if (data.status !== 'success' || !data.pinataUrl) {
        console.error('Pinata upload failed:', data);
        throw new Error('Pinata upload failed');
      }
  
      return data.pinataUrl; // This URL includes the correct file path
    } catch (error) {
      console.error('Error uploading to Pinata:', error);
      throw error;
    }
  };
  
  
  
  return (
    <div className="admin-products-container">
      {/* Tabs */}
      <div className="admin-tabs">
        <button
          className={`admin-tab ${activeTab === 'addProduct' ? 'active' : ''}`}
          onClick={() => setActiveTab('addProduct')}
        >
          Add Product
        </button>
        <button
          className={`admin-tab ${activeTab === 'manageProducts' ? 'active' : ''}`}
          onClick={() => setActiveTab('manageProducts')}
        >
          Manage Products
        </button>
      </div>

      {/* Add Product Tab */}
      {/* Add Product Tab */}
{activeTab === 'addProduct' && (
  <div className="add-product-tab">
    <h2>Add Product</h2>
    <div className="form-row">
      <label htmlFor="productName">Product Name:</label>
      <input
        id="productName"
        type="text"
        placeholder="Enter product name"
        value={productName}
        onChange={(e) => setProductName(e.target.value)}
      />
    </div>
    <div className="form-row">
      <label htmlFor="productPrice">Price:</label>
      <input
        id="productPrice"
        type="number"
        placeholder="Enter product price"
        value={productPrice}
        onChange={(e) => setProductPrice(e.target.value)}
      />
    </div>
    <div className="form-row">
      <label htmlFor="productCategory">Category:</label>
      <select
        id="productCategory"
        value={productCategory}
        onChange={(e) => setProductCategory(e.target.value)}
      >
        <option value="">Select Category</option>
        {categories.map((cat) => (
          <option key={cat.id} value={cat.id}>
            {cat.name}
          </option>
        ))}
      </select>
    </div>
    <div className="form-row">
      <label htmlFor="productImage">Product Image:</label>
      <input
        id="productImage"
        type="file"
        onChange={(e) => setProductImage(e.target.files[0])}
      />
    </div>
    <button onClick={handleAddProduct} disabled={uploading}>
      {uploading ? 'Uploading...' : 'Add Product'}
    </button>
  </div>
)}


{/* Manage Products Tab */}
{activeTab === 'manageProducts' && (
  <div className="manage-products-tab">
    <h2>Manage Products</h2>
    <div className="products-grid">
      {products.map((product) => {
        const category = categories.find((cat) => cat.id === product.category);

        return (
          <div key={product.id} className="product-card">
            {/* Main Row */}
            <div className="product-main-row">
              {/* Category Icon */}
              <div className="product-category-icon">
                {category?.iconUrl && (
                  <img
                    src={category.iconUrl}
                    alt={category.name}
                    className="category-icon"
                  />
                )}
              </div>

              {/* Category Name */}
              <div className="product-category-name">
                <p>{category?.name || 'Unknown'}</p>
              </div>

              {/* Product Name */}
              <div className="product-name">
                <p>{product.name}</p>
              </div>

              {/* Product Price */}
              <div className="product-price">
                <p>₦{product.price.toFixed(2)}</p>
              </div>

              {/* Action Button */}
              <div className="product-action">
                <button
                  className={`availability-btn ${
                    product.available ? 'available' : 'not-available'
                  }`}
                  onClick={() => toggleAvailability(product.id, product.available)}
                >
                  {product.available ? 'Mark as Unavailable' : 'Mark as Available'}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
)}


    </div>
  );
};

export default AdminProducts;
