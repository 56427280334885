import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUtensils, FaShoppingCart, FaUser, FaStar, FaCog } from 'react-icons/fa';
import './MobileNavs.css';
import { useAppContext } from '../context/AppWrapper';
import { useAuth } from '../context/AuthContext';

const MobileNavs = () => {
  const { cart, showLoader, hideLoader } = useAppContext();
  const { currentUser } = useAuth(); // Get authenticated user info
  const navigate = useNavigate();
  const location = useLocation();

  const totalItemsInCart = Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);

  const handleNavigation = (path) => {
    if (location.pathname !== path) {
      showLoader();
      setTimeout(() => {
        navigate(path);
        hideLoader();
      }, 500); // Simulate loader delay
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="mobile-nav">
      {[
        { path: '/', icon: FaUtensils, label: 'Menu' },
        { path: '/cart', icon: FaShoppingCart, label: 'Cart', count: totalItemsInCart },
        { path: '/account', icon: FaUser, label: 'Account' },
        {
          path: currentUser ? '/settings' : '/review',
          icon: currentUser ? FaCog : FaStar,
          label: currentUser ? 'Settings' : 'Review',
        },
      ].map(({ path, icon: Icon, label, count }) => (
        <motion.div
          key={path}
          onClick={() => handleNavigation(path)}
          className={`nav-item ${isActive(path) ? 'active' : ''}`}
          whileHover={{ scale: 1.2, rotate: 5 }} // Slight rotate and scale on hover
          whileTap={{ scale: 0.9 }} // Shrink on tap
          animate={isActive(path) ? { scale: 1.2 } : { scale: 1 }} // Active item scaling
        >
          <Icon className="nav-icon" />
          {count > 0 && <span className="cart-count">{count}</span>}
          <span>{label}</span>
        </motion.div>
      ))}
    </nav>
  );
};

export default MobileNavs;
