import React, { useState, useEffect } from "react";
import { database, ref, onValue } from "../firebase";
import "./BestSeller.css";

const BestSeller = () => {
  const [bestSellers, setBestSellers] = useState([]);

  useEffect(() => {
    const fetchBestSellers = () => {
      const ordersRef = ref(database, "orders"); // Fetch sales data
      const reviewsRef = ref(database, "reviews"); // Fetch reviews data

      const salesData = {};
      const reviewsData = {};

      // Fetch Orders
      onValue(ordersRef, (snapshot) => {
        const orders = snapshot.val();
        if (orders) {
          Object.values(orders).forEach((order) => {
            if (order.items) {
              order.items.forEach((item) => {
                if (item.productName && item.productName !== "service") {
                  if (!salesData[item.productId]) {
                    salesData[item.productId] = {
                      productName: item.productName,
                      salesCount: 0,
                    };
                  }
                  salesData[item.productId].salesCount += item.quantity || 1; // Increment sales count
                }
              });
            }
          });
        }
      });

      // Fetch Reviews
      onValue(reviewsRef, (snapshot) => {
        const reviews = snapshot.val();
        if (reviews) {
          Object.values(reviews).forEach((review) => {
            if (review.productId && review.productName !== "service") {
              if (!reviewsData[review.productId]) {
                reviewsData[review.productId] = {
                  ratingTotal: 0,
                  reviewCount: 0,
                };
              }
              reviewsData[review.productId].ratingTotal += review.rating;
              reviewsData[review.productId].reviewCount += 1;
            }
          });
        }

        // Combine Data
        const combinedData = Object.keys(salesData).map((productId) => {
          const sales = salesData[productId];
          const reviews = reviewsData[productId] || {
            ratingTotal: 0,
            reviewCount: 0,
          };
          const averageRating =
            reviews.reviewCount > 0
              ? (reviews.ratingTotal / reviews.reviewCount).toFixed(1)
              : "No Reviews";

          return {
            productName: sales.productName,
            timesSold: sales.salesCount,
            averageRating,
          };
        });

        // Sort by Times Sold
        combinedData.sort((a, b) => b.timesSold - a.timesSold);

        setBestSellers(combinedData);
      });
    };

    fetchBestSellers();
  }, []);

  return (
    <div className="best-sellers">
      <h2>Best Sellers</h2>

      {/* Desktop Table */}
      <table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Times Sold</th>
            <th>Average Rating</th>
          </tr>
        </thead>
        <tbody>
          {bestSellers.length > 0 ? (
            bestSellers.map((product, index) => (
              <tr key={index}>
                <td>{product.productName}</td>
                <td>{product.timesSold}</td>
                <td>{product.averageRating}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: "center" }}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Mobile Cards */}
      <div className="mobile-list">
        {bestSellers.length > 0 ? (
          bestSellers.map((product, index) => (
            <div key={index} className="mobile-card">
              <h3>{product.productName}</h3>
              <p>Times Sold: {product.timesSold}</p>
              <p>Average Rating: {product.averageRating}</p>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", color: "#999" }}>No data available</p>
        )}
      </div>
    </div>
  );
};

export default BestSeller;
