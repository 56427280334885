import React, { useState } from 'react';
import { FaBox, FaHourglassHalf, FaShippingFast, FaCheckCircle, FaSearch, FaQuestionCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, get } from 'firebase/database';
import './TrackOrder.css';

const TrackOrder = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false); // State for the help modal

  const handleTrackOrder = async () => {
    if (!trackingNumber) {
      toast.error('Please enter a valid tracking number!');
      return;
    }

    setLoading(true);

    try {
      const orderRef = ref(getDatabase(), 'orders/' + trackingNumber);
      const snapshot = await get(orderRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log('Order status:', data.status);
        setOrderStatus(data.status);
        setLoading(false);
      } else {
        toast.error('Order not found.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching order status:', error);
      toast.error('Error fetching order status.');
      setLoading(false);
    }
  };

  const sections = [
    { label: 'Order Received', icon: <FaBox />, statusKey: 'order_received', description: 'Your order has been received and is being processed.' },
    { label: 'Preparing Order', icon: <FaHourglassHalf />, statusKey: 'preparing', description: 'Your order is being prepared by our team.' },
    { label: 'Packing Order', icon: <FaBox />, statusKey: 'packing', description: 'Your order is being packed for shipment.' },
    { label: 'Out for Delivery', icon: <FaShippingFast />, statusKey: 'out_for_delivery', description: 'Your order is out for delivery and on the way to you.' },
    { label: 'Delivered', icon: <FaCheckCircle />, statusKey: 'delivered', description: 'Your order has been delivered successfully.' },
  ];

  return (
    <div className="track-order-container">
      {/* Header */}
      <h1>Track Your Order</h1>
      <div className="intro-container">
        <p>
          Welcome to the order tracking page. Enter your tracking number to check the current status of your order. 
          Click on the help button to know what each order status means:
        </p>
      </div>

      {/* Help Icon */}
      <div className="help-icon" onClick={() => setShowHelp(true)}>
        <FaQuestionCircle />
      </div>

      {/* Modal for Help */}
      {showHelp && (
        <div className="help-modal">
          <div className="modal-content">
            <h2>Order Status Guide</h2>
            {sections.map((section) => (
              <div key={section.statusKey} className="status-description">
                <div className="status-icon">{section.icon}</div>
                <div className="status-details">
                  <h4>{section.label}</h4>
                  <p>{section.description}</p>
                </div>
              </div>
            ))}
            <button onClick={() => setShowHelp(false)} className="close-modal-btn">
              Close
            </button>
          </div>
        </div>
      )}

      {/* Track Order Input */}
      <div className="track-input-container">
        <input
          type="text"
          placeholder="Enter Tracking Number"
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
        />
        <button className="track-btn" onClick={handleTrackOrder}>
          {loading ? <div className="spinner"></div> : <FaSearch />}
          {loading ? 'Tracking...' : 'Track'}
        </button>
      </div>

      {/* Order Status Display */}
      {orderStatus && (
        <div className="order-status-container">
          {sections.map((section, index) => (
            orderStatus[section.statusKey] && (
              <div key={index} className="status-section completed">
                <div className="status-icon">{section.icon}</div>
                <div className="status-label">{section.label}</div>
                <div className="status-completed">Completed</div>
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default TrackOrder;
