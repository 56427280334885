import React, { useState, useEffect } from "react";
import "./DatabaseBackup.css";

const DatabaseBackup = () => {
  const [backups, setBackups] = useState([]); // Backup file information
  const [loading, setLoading] = useState(false); // Spinner for actions

  useEffect(() => {
    // Fetch backup details from backend
    fetch("https://firstfltb.com/api3/fetchBackups.php")
      .then((res) => res.json())
      .then((data) => {
        if (data.backups) {
          setBackups(data.backups);
        }
      })
      .catch((error) => console.error("Error fetching backups:", error));
  }, []);

  const handleBackup = () => {
    setLoading(true);
    fetch("https://firstfltb.com/api3/triggerBackup.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert(data.message || "Backup triggered successfully!");
        window.location.reload(); // Refresh to show updated backup info
      })
      .catch((error) => {
        alert("Backup failed");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleRestore = (fileName) => {
    if (!window.confirm("Are you sure you want to restore this backup?")) return;
    setLoading(true);

    fetch("https://firstfltb.com/api3/restoreBackup.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ backupFileName: fileName }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          alert("Backup restored successfully!");
        } else {
          alert("Restore failed: " + data.message);
        }
      })
      .catch((error) => {
        alert("Restore failed");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="database-backup-container">
      <h2>Database Backup Management</h2>

      {/* Run Manual Backup Button */}
      <div className="manual-backup-container">
        <button onClick={handleBackup} className="manual-backup-btn" disabled={loading}>
          {loading ? "Processing..." : "Run Manual Backup"}
        </button>
      </div>

      {/* Header Row */}
      <div className="backup-header">
        <span>Last Backup Date</span>
        <span>Next Backup Date</span>
        <span>Backup File</span>
        <span>Actions</span>
      </div>

      {/* Backup Rows */}
      {backups.length > 0 ? (
        backups.map((backup, index) => (
            <div key={index} className="backup-row">
            <span>{backup.lastBackupDate}</span>
            <span>{backup.nextBackupDate}</span>
            <span>{backup.fileName}</span>
            <span className="action-buttons">
              <a
                  href={`https://firstfltb.com/api3/downloadBackup.php?fileName=${backup.fileName}`}
                download={backup.fileName} // Trigger automatic download
                className="download-btn"
              >
                Download
              </a>
              <button
                onClick={() => handleRestore(backup.fileName)}
                className="restore-btn"
                disabled={loading}
              >
                {loading ? "Processing..." : "Restore"}
              </button>
            </span>
          </div>
          
        ))
      ) : (
        <p className="no-backups">No backups found.</p>
      )}
    </div>
  );
};

export default DatabaseBackup;
