import React, { useState } from 'react';
import { ref, set, database } from '../../firebase'; // Firebase imports
import './PaymentGateway.css';

const PaymentGateway = () => {
  const [selectedGateway, setSelectedGateway] = useState('');
  const [config, setConfig] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false); // Scoped variable
  const [setupInstructions, setSetupInstructions] = useState('');

  const handleGatewayChange = (e) => {
    setSelectedGateway(e.target.value);
    setConfig({});
  };

  const handleConfigChange = (e) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowSetupModal = (gateway) => {
    const instructions = {
      paystack: `
        1. Sign up for a Paystack account at https://paystack.com.
        2. Navigate to the "Settings" > "API Keys & Webhooks" section.
        3. Copy your Public Key and Secret Key.
        4. Add your public and secret keys to the form below.
        5. Ensure you set up your webhook URL in Paystack as: https://yourwebsite.com/paystack-webhook.
      `,
      flutterwave: `
        1. Create an account on Flutterwave at https://flutterwave.com.
        2. Go to "Settings" > "API Keys" to access your Public and Secret Keys.
        3. Add your public and secret keys to the form below.
        4. Configure the webhook URL in Flutterwave as: https://yourwebsite.com/flutterwave-webhook.
      `,
      seerbit: `
        1. Register on SeerBit at https://seerbit.com.
        2. Access your Public Key and Secret Key from the "Developers" section.
        3. Enter the keys into the form below.
        4. Configure webhook callbacks for transaction updates.
      `,
    };
    setSetupInstructions(instructions[gateway]);
    setShowPaymentModal(true);
  };

  const handleSave = async () => {
    if (!selectedGateway || !config.publicKey || !config.secretKey) {
      alert('Please fill all fields.');
      return;
    }

    try {
      const gatewayRef = ref(database, `paymentGateways/${selectedGateway}`);
      await set(gatewayRef, {
        ...config,
        webhookUrl: `https://yourwebsite.com/${selectedGateway}-webhook`,
      });

      alert('Payment gateway settings saved successfully!');
    } catch (error) {
      console.error('Error saving payment gateway settings:', error);
      alert('Failed to save settings. Please try again.');
    }
  };

  return (
    <div className="payment-gateway-container">
  <h2>Payment Gateway Settings</h2>

  {/* Dropdown to select payment gateway */}
  <div className="payment-gateway-form-row">
    <label htmlFor="gateway">Select Gateway:</label>
    <select id="gateway" value={selectedGateway} onChange={handleGatewayChange}>
      <option value="">--Select--</option>
      <option value="paystack">Paystack</option>
      <option value="flutterwave">Flutterwave</option>
      <option value="seerbit">SeerBit</option>
    </select>
  </div>

  {/* Configuration Row */}
  {selectedGateway && (
    <div className="payment-gateway-config-row">
      <button
        className="setup-btn"
        onClick={() => handleShowSetupModal(selectedGateway)}
      >
        How to Set Up
      </button>
      <input
        type="text"
        name="publicKey"
        placeholder="Public Key"
        value={config.publicKey || ''}
        onChange={handleConfigChange}
      />
      <input
        type="text"
        name="secretKey"
        placeholder="Secret Key"
        value={config.secretKey || ''}
        onChange={handleConfigChange}
      />
      <input
        type="text"
        name="webhookUrl"
        placeholder={`https://yourwebsite.com/${selectedGateway}-webhook`}
        value={`https://yourwebsite.com/${selectedGateway}-webhook`}
        disabled
      />
      <button className="save-btn" onClick={handleSave}>
        Save Settings
      </button>
    </div>
  )}

  {/* Payment Gateway Modal */}
  {showPaymentModal && (
    <div className="payment-gateway-modal">
      <div className="payment-gateway-modal-content">
        <h3>Setup Instructions</h3>
        <ol>
          {setupInstructions
            .trim()
            .split('\n')
            .filter((instruction) => instruction.trim())
            .map((instruction, index) => (
              <li key={index}>{instruction.trim()}</li>
            ))}
        </ol>
        <button
          className="payment-gateway-close-modal-btn"
          onClick={() => setShowPaymentModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  )}
</div>

  );
};

export default PaymentGateway;
