import React from 'react';
import { FaWhatsapp, FaEnvelope, FaTwitter } from 'react-icons/fa';
import './DeveloperFooter.css';

function DeveloperFooter() {
  return (
    <footer className="developer-footer">
      <p className="footer-text">
        Developed with ❤️ by <strong>WebDev Inc</strong>
      </p>
      <div className="footer-icons">
        {/* WhatsApp link */}
        <a
          href="https://wa.me/+2349069339736" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon whatsapp"
        >
          <FaWhatsapp />
        </a>
        {/* Email link */}
        <a
          href="mailto:webdev@bzzinc.com" // Replace with your email
          className="footer-icon envelope"
        >
          <FaEnvelope />
        </a>
        {/* X (formerly Twitter) link */}
        <a
          href="https://x.com/webdev_v2?s=21" // Replace with your Twitter/X profile
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon twitter"
        >
          <FaTwitter />
        </a>
      </div>
    </footer>
  );
}

export default DeveloperFooter;
