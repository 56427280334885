import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Assuming your auth context provides user info and update functions
import { toast } from 'react-toastify'; // For displaying notifications
import 'react-toastify/dist/ReactToastify.css';
import { FaUser, FaPhone, FaEnvelope, FaHome, FaLock } from 'react-icons/fa'; // Icons for each input field
import './UserSettings.css'; // For custom styling

const UserSettings = () => {
  const { currentUser, updateUserProfile, updateUserPassword } = useAuth(); // Assuming update functions are provided
  const [fullName, setFullName] = useState(currentUser?.displayName || '');
  const [address, setAddress] = useState(currentUser?.address || '');
  const [mobileNumber, setMobileNumber] = useState(currentUser?.phoneNumber || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fullName || !address || !mobileNumber || !email) {
      toast.error('Please fill in all fields!');
      return;
    }
  
    try {
      await updateUserProfile({ fullName, address, mobileNumber, email });
      toast.success('Profile updated successfully!');
    } catch (error) {
      toast.error('Error updating profile: ' + error.message);
    }
  };
  
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error('Please fill in all password fields!');
      return;
    }
  
    if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password do not match!');
      return;
    }
  
    try {
      await updateUserPassword(newPassword);
      toast.success('Password changed successfully!');
    } catch (error) {
      toast.error('Error changing password: ' + error.message);
    }
  };
  

  return (
    <div className="user-settings-container">
      <h2>User Settings</h2>

      {/* User Information Form */}
      <form onSubmit={handleSubmit} className="user-info-form">
        <div className="form-group">
          <label>Full Name</label>
          <div className="input-with-icon">
            <FaUser className="input-icon" />
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Address</label>
          <div className="input-with-icon">
            <FaHome className="input-icon" />
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Mobile Number</label>
          <div className="input-with-icon">
            <FaPhone className="input-icon" />
            <input
              type="tel"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Email</label>
          <div className="input-with-icon">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="save-btn">Save Changes</button>
      </form>

      {/* Change Password Form */}
      <form onSubmit={handlePasswordChange} className="change-password-form">
        <h3>Change Password</h3>
        <div className="form-group">
          <label>Old Password</label>
          <div className="input-with-icon">
            <FaLock className="input-icon" />
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>New Password</label>
          <div className="input-with-icon">
            <FaLock className="input-icon" />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-group">
          <label>Confirm New Password</label>
          <div className="input-with-icon">
            <FaLock className="input-icon" />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="save-btn">Change Password</button>
      </form>
    </div>
  );
};

export default UserSettings;
