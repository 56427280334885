import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminDashboard from './AdminDashboard';
import AdminCategories from './AdminCategories';
import AdminProducts from './AdminProducts';
import AdminHeader from './AdminHeader'; // Import the AdminHeader component
import UsersAdmin from './UsersAdmin'; // Import the UsersAdmin component
import AdminTransaction from './AdminTransaction'; // Import the AdminTransaction component
import AdminSettings from './AdminSettings'; // Import the AdminSettings component
import AdminReview from './AdminReview'; // Import AdminReview component
import BestSeller from './BestSeller'; // Import BestSeller component
import AdminOrders from './AdminOrders';
import AdminActivities from './AdminActivities';
import AdminChatWidget from './AdminChatWidget'; // Import the AdminChatWidget component
import { database, ref, onValue } from '../firebase';

const AdminRoutes = () => {
  const [categories, setCategories] = useState([]);

  // Fetch categories from Firebase
  useEffect(() => {
    const categoriesRef = ref(database, 'categories');
    onValue(categoriesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedCategories = Object.values(data);
        setCategories(formattedCategories);
      } else {
        setCategories([]);
      }
    });
  }, []);

  return (
    <div className="admin-container">
      <AdminHeader /> {/* Sticky Admin Header */}
      <Routes>
        <Route path="login" element={<AdminLogin />} />
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="products" element={<AdminProducts categories={categories} />} />
        <Route path="categories" element={<AdminCategories />} />
        <Route path="users" element={<UsersAdmin />} />
        <Route path="transactions" element={<AdminTransaction />} />
        <Route path="settings" element={<AdminSettings />} />
        <Route path="reviews" element={<AdminReview />} />
        <Route path="best-sellers" element={<BestSeller />} />
        <Route path="orders" element={<AdminOrders />} />
        <Route path="activities" element={<AdminActivities />} />
      </Routes>
      <AdminChatWidget /> {/* Sticky Chat Widget */}
    </div>
  );
};

export default AdminRoutes;
