import React from 'react';

const Favourite = () => {
  return (
    <div className="favorite-page">
      <h1>Your Favourites</h1>
      <p>Manage your favorite products here.</p>
      <div className="favorites-list">
        {/* Placeholder for favorite items */}
        <div className="favorite-item">
          <img
            src="https://via.placeholder.com/150"
            alt="Placeholder Product"
            className="product-image"
          />
          <h3>Product Name</h3>
          <p>Product description goes here</p>
          <button className="add-favorite-btn">Add to Favorites</button>
        </div>
        <div className="favorite-item">
          <img
            src="https://via.placeholder.com/150"
            alt="Placeholder Product"
            className="product-image"
          />
          <h3>Product Name</h3>
          <p>Product description goes here</p>
          <button className="add-favorite-btn">Add to Favorites</button>
        </div>
      </div>
    </div>
  );
};

export default Favourite;
