import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaPhone, FaGoogle, FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  auth,
  googleProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  database,
  ref,
  set,
} from '../firebase';
import { useAppContext } from '../context/AppWrapper'; // Import the loader functions
import './EnhancedAccount.css';

const Account = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppContext(); // Access loader functions from context
  const [currentForm, setCurrentForm] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState(''); // New field for full name
  const [showPassword, setShowPassword] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  // Handle show/hide password
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // Track authentication state in real-time
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) navigate('/dashboard'); // Redirect authenticated users to the dashboard
    });
    return () => unsubscribe(); // Clean up listener
  }, [navigate]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader(); // Show loader during form submission

    if (currentForm === 'login') {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        toast.success(`Welcome back, ${user.email}!`);
        navigate('/dashboard'); // Redirect to dashboard after login
      } catch (error) {
        toast.error(`Login failed: ${error.message}`);
      } finally {
        hideLoader(); // Hide loader after login
      }
    } else if (currentForm === 'signup') {
      if (!phone || !fullName) {
        toast.error('Please provide your full name and phone number.');
        hideLoader();
        return;
      }

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Save additional user data to Realtime Database
        await set(ref(database, `users/${user.uid}`), {
          email,
          phone,
          fullName,
        });

        toast.success(`Account created for ${email}!`);
        navigate('/dashboard'); // Redirect to dashboard after signup
      } catch (error) {
        toast.error(`Sign up failed: ${error.message}`);
      } finally {
        hideLoader(); // Hide loader after signup
      }
    } else if (currentForm === 'resetPassword') {
      try {
        await sendPasswordResetEmail(auth, email);
        toast.success('Password reset email sent!');
      } catch (error) {
        toast.error(`Password reset failed: ${error.message}`);
      } finally {
        hideLoader(); // Hide loader after password reset
      }
    }
  };

  // Google Authentication
  const handleGoogleAuth = async () => {
    showLoader(); // Show loader during Google auth
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Check if additional info is needed
      const userRef = ref(database, `users/${user.uid}`);
      const snapshot = await userRef.get();

      if (!snapshot.exists()) {
        // Prompt user to fill in missing info
        toast.info('Please complete your profile with your name and phone number.');
        setFullName(user.displayName || ''); // Pre-fill name if available
        setEmail(user.email);
        setCurrentForm('signup'); // Switch to signup form to collect extra info
      } else {
        toast.success(`Welcome, ${user.displayName || user.email}!`);
        navigate('/dashboard');
      }
    } catch (error) {
      toast.error(`Google login failed: ${error.message}`);
    } finally {
      hideLoader(); // Hide loader after Google auth
    }
  };

  return (
    <div className="enhanced-account-container">
      <div className="enhanced-account-form-container">
        <h2 className="enhanced-form-heading">
          {currentForm === 'login' ? 'Login' : currentForm === 'signup' ? 'Sign Up' : 'Reset Password'}
        </h2>
        <form onSubmit={handleSubmit} className="enhanced-account-form">
          {/* Full Name Input (only for signup) */}
          {currentForm === 'signup' && (
            <div className="enhanced-input-group">
              <FaUser className="enhanced-input-icon" />
              <input
                type="text"
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
          )}

          {/* Email Input */}
          <div className="enhanced-input-group">
            <FaEnvelope className="enhanced-input-icon" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Password Input (only for login or signup) */}
          {currentForm !== 'resetPassword' && (
            <div className="enhanced-input-group">
              <FaLock className="enhanced-input-icon" />
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={togglePasswordVisibility} className="enhanced-password-toggle-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          )}

          {/* Phone Number Input (only for signup) */}
          {currentForm === 'signup' && (
            <div className="enhanced-input-group">
              <FaPhone className="enhanced-input-icon" />
              <input
                type="tel"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
          )}

          {/* Submit Button */}
          <button type="submit" className="enhanced-submit-btn">
            {currentForm === 'login' ? 'Login' : currentForm === 'signup' ? 'Sign Up' : 'Reset Password'}
          </button>

          {/* Google Login Button */}
          <button type="button" onClick={handleGoogleAuth} className="enhanced-google-btn">
            <FaGoogle /> {currentForm === 'signup' ? 'Sign Up with Google' : 'Login with Google'}
          </button>

          {/* Forgot Password Link (Only for Login) */}
          {currentForm === 'login' && (
            <div className="enhanced-forgot-password" onClick={() => setCurrentForm('resetPassword')}>
              Forgot Password?
            </div>
          )}

          {/* Switch Between Login, Sign Up, and Reset Password */}
          <div className="enhanced-switch-action">
            {currentForm === 'login' ? (
              <p>
                Don't have an account?{' '}
                <span onClick={() => setCurrentForm('signup')} className="enhanced-switch-link">
                  Sign up
                </span>
              </p>
            ) : currentForm === 'signup' ? (
              <p>
                Already have an account?{' '}
                <span onClick={() => setCurrentForm('login')} className="enhanced-switch-link">
                  Login
                </span>
              </p>
            ) : (
              <p>
                Remembered your password?{' '}
                <span onClick={() => setCurrentForm('login')} className="enhanced-switch-link">
                  Login
                </span>
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Account;
