import React from 'react';
import { FaUser, FaPhone, FaMapMarkerAlt, FaCity, FaEnvelope, FaGlobe } from 'react-icons/fa';
import './Details.css';

const Details = ({ formData, handleInputChange }) => {
  return (
    <div className="details-section">
      <div className="header">
        <h2>Provide Your Details</h2>
      </div>
      <div className="form-grid">
        <div className="form-input">
          <FaUser className="form-icon" />
          <input
            type="text"
            name="name"
            placeholder="Name __________"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input">
          <FaPhone className="form-icon" />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number __________"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input">
          <FaMapMarkerAlt className="form-icon" />
          <input
            type="text"
            name="address"
            placeholder="Address __________"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input">
          <FaCity className="form-icon" />
          <input
            type="text"
            name="city"
            placeholder="City __________"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input">
          <FaGlobe className="form-icon" />
          <input
            type="text"
            name="state"
            placeholder="State __________"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input">
  <FaEnvelope className="form-icon" />
  <input
    type="email"
    name="email"
    placeholder="Email __________"
    value={formData.email}
    onChange={handleInputChange}
  />
</div>


      </div>
    </div>
  );
};

export default Details;
