import React, { useEffect, useState } from 'react';
import { ref, onValue, update, database } from '../firebase';
import { FaBox, FaHourglassHalf, FaShippingFast, FaCheckCircle } from 'react-icons/fa';
import './AdminOrders.css'; // Include custom CSS
import Modal from 'react-modal'; // Modal for showing order details

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null); // For the modal
  const [modalOpen, setModalOpen] = useState(false);

  const sections = [
    { label: 'Order Received', icon: <FaBox />, statusKey: 'order_received', description: 'Your order has been received and is being processed.' },
    { label: 'Preparing Order', icon: <FaHourglassHalf />, statusKey: 'preparing', description: 'Your order is being prepared by our team.' },
    { label: 'Packing Order', icon: <FaBox />, statusKey: 'packing', description: 'Your order is being packed for shipment.' },
    { label: 'Out for Delivery', icon: <FaShippingFast />, statusKey: 'out_for_delivery', description: 'Your order is out for delivery and on the way to you.' },
    { label: 'Delivered', icon: <FaCheckCircle />, statusKey: 'delivered', description: 'Your order has been delivered successfully.' },
  ];

  // Fetch orders from Firebase
  useEffect(() => {
    const ordersRef = ref(database, 'orders');
    onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setOrders(Object.values(data));
      } else {
        setOrders([]);
      }
    });
  }, []);

  // Handle status update
  const handleStatusUpdate = (orderId, newStatus) => {
    const orderRef = ref(database, `orders/${orderId}`);
    update(orderRef, { status: newStatus })
      .then(() => {
        alert('Order status updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating order status:', error);
        alert('Failed to update order status.');
      });
  };

  // Handle opening the modal
  const openModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setSelectedOrder(null);
    setModalOpen(false);
  };

  return (
    <div className="admin-orders">
      <h2>Manage Orders</h2>
      {orders.length > 0 ? (
        <table className="orders-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Order Number</th>
              <th>Delivery Method</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{order.customerName}</td>
                <td>
                  <button className="order-number-btn" onClick={() => openModal(order)}>
                    {order.trackingNumber}
                  </button>
                </td>
                <td>{order.deliveryMethod}</td>
                <td>
                  <select
                    value={order.status}
                    onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
                  >
                    {sections.map((section) => (
                      <option key={section.statusKey} value={section.statusKey}>
                        {section.label}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-orders-message">No orders available at the moment.</p>
      )}

      {/* Order Modal */}
      {selectedOrder && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="Order Details"
          className="order-modal"
          overlayClassName="order-modal-overlay"
        >
          <h2>Order Details</h2>
          <p><strong>Order Number:</strong> {selectedOrder.trackingNumber}</p>
          <p><strong>Customer Name:</strong> {selectedOrder.customerName}</p>
          <p><strong>Delivery Method:</strong> {selectedOrder.deliveryMethod}</p>
          <p><strong>Status:</strong> {sections.find((s) => s.statusKey === selectedOrder.status)?.label}</p>
          <h3>Items:</h3>
          <ul>
            {selectedOrder.items.map((item, index) => (
              <li key={index}>
                {item.name} - Qty: {item.quantity} - ₦{item.price.toFixed(2)}
              </li>
            ))}
          </ul>
          <button onClick={closeModal} className="close-modal-btn">Close</button>
        </Modal>
      )}
    </div>
  );
};

export default AdminOrders;
