// src/utils/TempData.jsx
import { FaHamburger, FaIceCream, FaCoffee } from 'react-icons/fa';
import { GiDonut } from 'react-icons/gi';
import burger1 from '../Assets/burgers/burger1.jpg';
import burger2 from '../Assets/burgers/burger2.jpg';
import cocaCola from '../Assets/drinks/coca-cola.webp';
import lemonade from '../Assets/drinks/lemonade.webp';
import cheeseFries from '../Assets/fries/cheese-fries.webp';
import classicFries from '../Assets/fries/classic-fries.webp';
import vanillaMilkshake from '../Assets/milkshakes/vanilla-milkshake.webp';
import blueberryParfait from '../Assets/parfaits/blueberry-parfait.webp';
import strawberryParfait from '../Assets/parfaits/strawberry-parfait.webp';

export const menuItems = [
  { id: 1, name: 'Beef Burger', category: 'Burgers', price: 8.99, image: burger1, icon: <FaHamburger style={{ color: '#FF5733' }} /> },
  { id: 2, name: 'Chicken Burger', category: 'Burgers', price: 9.99, image: burger2, icon: <FaHamburger style={{ color: '#FF5733' }} /> },
  { id: 3, name: 'Coca-Cola', category: 'Drinks', price: 1.99, image: cocaCola, icon: <FaCoffee style={{ color: '#33A1FF' }} /> },
  { id: 4, name: 'Lemonade', category: 'Drinks', price: 2.49, image: lemonade, icon: <FaCoffee style={{ color: '#33A1FF' }} /> },
  { id: 5, name: 'Cheese Fries', category: 'Fries', price: 3.49, image: cheeseFries, icon: <FaCoffee style={{ color: '#FFD700' }} /> },
  { id: 6, name: 'Classic Fries', category: 'Fries', price: 2.99, image: classicFries, icon: <FaCoffee style={{ color: '#FFD700' }} /> },
  { id: 7, name: 'Vanilla Milkshake', category: 'Milkshakes', price: 4.99, image: vanillaMilkshake, icon: <FaCoffee style={{ color: '#FFB6C1' }} /> },
  { id: 8, name: 'Blueberry Parfait', category: 'Parfaits', price: 5.49, image: blueberryParfait, icon: <FaIceCream style={{ color: '#9370DB' }} /> },
  { id: 9, name: 'Strawberry Parfait', category: 'Parfaits', price: 5.49, image: strawberryParfait, icon: <FaIceCream style={{ color: '#9370DB' }} /> },
  { id: 10, name: 'Chocolate Doughnut', category: 'Doughnuts', price: 2.99, image: classicFries, icon: <GiDonut style={{ color: '#8B4513' }} /> },
];
