import React, { useState, useEffect } from 'react';
import { ref, push, onValue, database } from '../firebase'; // Using Firebase
import { useAuth } from '../context/AuthContext'; // To get the logged-in user
import './AdminTransaction.css';

const AdminTransaction = () => {
  const { currentUser } = useAuth(); // Get the current authenticated user
  const [transactions, setTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    type: 'web', // Default type
    amount: '',
    description: '',
  });
  const [filters, setFilters] = useState({ date: '', month: '', year: '' });
  const [total, setTotal] = useState(0);

  // Formatter for currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount);
  };

  // Fetch transactions from Firebase
  useEffect(() => {
    const transactionsRef = ref(database, 'transactions');
    onValue(transactionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedTransactions = Object.values(data);
        setTransactions(formattedTransactions);
        calculateTotal(formattedTransactions);
      } else {
        setTransactions([]);
        setTotal(0);
      }
    });
  }, []);

  // Calculate Total Balance
  const calculateTotal = (transactions) => {
    const total = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    setTotal(total);
  };

  // Handle New Transaction Submission
  const handleAddTransaction = async () => {
    if (!newTransaction.amount || !newTransaction.description) {
      alert('Please fill out all fields');
      return;
    }
  
    try {
      const transaction = {
        ...newTransaction,
        amount: parseFloat(newTransaction.amount),
        date: new Date().toISOString(),
        addedBy: currentUser?.displayName || currentUser?.email, // Use full name or email
      };
  
      const transactionsRef = ref(database, 'transactions');
      await push(transactionsRef, transaction);
  
      // Log admin activity
      const activity = {
        name: currentUser?.displayName || currentUser?.email, // Use full name or email
        action: `Added a transaction: ${transaction.description} (${formatCurrency(transaction.amount)})`,
        timestamp: Date.now(),
      };
  
      const activitiesRef = ref(database, 'adminActivities');
      await push(activitiesRef, activity);
  
      alert('Transaction logged successfully!');
      setNewTransaction({ type: 'web', amount: '', description: '' });
    } catch (error) {
      console.error('Error adding transaction:', error);
      alert('Failed to log transaction.');
    }
  };
  

  // Filter Transactions
  const filteredTransactions = transactions.filter((transaction) => {
    const transactionDate = new Date(transaction.date);
    const transactionMonth = transactionDate.getMonth() + 1;
    const transactionYear = transactionDate.getFullYear();

    return (
      (!filters.date || filters.date === transactionDate.toISOString().split('T')[0]) &&
      (!filters.month || parseInt(filters.month) === transactionMonth) &&
      (!filters.year || parseInt(filters.year) === transactionYear)
    );
  });

  // Export Data to CSV
  const exportData = () => {
    const headers = ['Date,Time,Type,Amount,Description,Added By\n'];
    const rows = transactions.map((t) => {
      const date = new Date(t.date);
      return [
        date.toLocaleDateString(),
        date.toLocaleTimeString(),
        t.type,
        formatCurrency(t.amount),
        t.description,
        t.addedBy,
      ].join(',');
    });
    const csvContent = headers.concat(rows).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `transactions-${new Date().toISOString()}.csv`;
    link.click();
  };

  return (
    <div className="admin-transaction-container">
      {/* Total Balance */}
      <div className="total-balance">
        <h3>Total Balance: {formatCurrency(total)}</h3>
      </div>

      {/* Filters */}
      <div className="filters">
        <input
          type="date"
          value={filters.date}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        />
        <select
          value={filters.month}
          onChange={(e) => setFilters({ ...filters, month: e.target.value })}
        >
          <option value="">Filter by Month</option>
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <select
          value={filters.year}
          onChange={(e) => setFilters({ ...filters, year: e.target.value })}
        >
          <option value="">Filter by Year</option>
          {[...Array(10)].map((_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
        <button onClick={exportData}>Export CSV</button>
      </div>

      {/* Add Transaction Form */}
      <div className="add-transaction-form">
        <h3>Log New Transaction</h3>
        <select
          value={newTransaction.type}
          onChange={(e) => setNewTransaction({ ...newTransaction, type: e.target.value })}
        >
          <option value="web">Web</option>
          <option value="whatsapp">WhatsApp</option>
          <option value="shop">Shop</option>
        </select>
        <input
          type="number"
          placeholder="Amount"
          value={newTransaction.amount}
          onChange={(e) => setNewTransaction({ ...newTransaction, amount: e.target.value })}
        />
        <input
          type="text"
          placeholder="Description"
          value={newTransaction.description}
          onChange={(e) => setNewTransaction({ ...newTransaction, description: e.target.value })}
        />
        <button onClick={handleAddTransaction}>Add Transaction</button>
      </div>

      {/* Transactions Table */}
      <div className="transactions-table">
        <div className="transactions-header">
          <p>Date</p>
          <p>Time</p>
          <p>Type</p>
          <p>Amount</p>
          <p>Description</p>
          <p>Added By</p>
        </div>
        {filteredTransactions.map((transaction, index) => {
          const date = new Date(transaction.date);
          return (
            <div key={index} className="transaction-item">
              <p>{date.toLocaleDateString()}</p>
              <p>{date.toLocaleTimeString()}</p>
              <p>{transaction.type}</p>
              <p>{formatCurrency(transaction.amount)}</p>
              <p>{transaction.description}</p>
              <p>{transaction.addedBy}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminTransaction;
