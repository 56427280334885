import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaTruck, FaHistory, FaTicketAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'; // Icons for sections
import './UserHome.css';

const UserHome = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  // Handle Logout
  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home page
  };

  // Current Date and Time
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  const getDateAndTime = () => {
    const now = new Date();
    setCurrentDate(now.toLocaleDateString());
    setCurrentTime(now.toLocaleTimeString());
  };

  useEffect(() => {
    getDateAndTime();
    const interval = setInterval(getDateAndTime, 60000); // Update time every minute
    return () => clearInterval(interval); // Cleanup interval
  }, []);

  const sections = [
    {
      title: 'Track My Order',
      description: 'View the status of your current orders.',
      icon: <FaTruck />,
      onClick: () => navigate('/track-order'),
    },
    {
      title: 'Order History',
      description: 'Check your previous order details.',
      icon: <FaHistory />,
      onClick: () => navigate('/order-history'),
    },
    {
      title: 'Voucher',
      description: 'View or use available vouchers and discounts.',
      icon: <FaTicketAlt />,
      onClick: () => navigate('/vouchers'),
    },
  ];

  return (
    <div className="user-home-container">
      {/* Sticky Header */}
      <header className="user-dashboard-header">
        <h1>Dashboard</h1>
      </header>

      {/* Cards Grid */}
      <div className="user-home-grid">
        {/* User Info Card */}
        <div className="user-card user-info-card">
          <div className="user-icon">
            <FaUser />
          </div>
          <div className="user-info">
            <p><strong>Name:</strong> {currentUser?.displayName || 'N/A'}</p>
            <p><strong>Date:</strong> {currentDate}</p>
            <p><strong>Email:</strong> {currentUser?.email}</p>
            <p><strong>Time:</strong> {currentTime}</p>
          </div>
        </div>

        {/* Section Cards */}
        {sections.map((section, index) => (
          <div key={index} className="user-card section-card" onClick={section.onClick}>
            <div className="section-icon">{section.icon}</div>
            <div className="section-details">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Floating Logout Button */}
      <button className="floating-logout-btn" onClick={handleLogout}>
        <FaSignOutAlt />
      </button>
    </div>
  );
};

export default UserHome;
