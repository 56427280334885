import React, { useState, useEffect } from 'react';
import { useAppContext } from '../context/AppWrapper';
import Details from './checkout/Details';
import DeliveryPaymentContact from './checkout/DeliveryPaymentContact';
import OrderSummary from './checkout/OrderSummary';
import { menuItems } from '../utils/TempData';
import { FaArrowLeft, FaArrowRight, FaCheck, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './Checkout.css';

const Checkout = () => {
  const { cart, showLoader, hideLoader } = useAppContext();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const [formData, setFormData] = useState(() => {
    const savedForm = JSON.parse(localStorage.getItem('formData'));
    return savedForm || {
      name: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      email: '',
      deliveryMethod: 'Delivery',
      paymentMethod: 'Card',
      contactMethod: 'Phone Call',
      contactDetail: '',
      agreeToTerms: false,
    };
  });

  const cartItems = Object.keys(cart).map((id) => ({
    ...menuItems.find((menuItem) => menuItem.id === parseInt(id)),
    quantity: cart[id],
  }));

  const subtotal = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const deliveryFee = formData.deliveryMethod === 'Delivery' ? 10 : 0;
  const total = subtotal + deliveryFee;

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = (isFinalStep = false) => {
    const missingFields = [];
    if (!formData.name) missingFields.push("Name");
    if (!formData.phone) missingFields.push("Phone Number");
    if (!formData.address) missingFields.push("Address");
    if (!formData.city) missingFields.push("City");
    if (!formData.state) missingFields.push("State");

    if (missingFields.length > 0) {
      toast.error(`Please fill in: ${missingFields.join(", ")}`);
      return false;
    }

    if (isFinalStep && !formData.agreeToTerms) {
      toast.error("You must agree to the terms before placing the order.");
      return false;
    }

    return true;
  };

  const handleNext = () => {
    if (!validateForm()) return;
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep((prevStep) => prevStep - 1);
  };

  const handleEditCart = () => {
    localStorage.setItem('formData', JSON.stringify(formData)); // Save form data
    navigate('/cart'); // Navigate back to Cart page
  };

  const handleSubmit = async () => {
    // Validate form data before proceeding
    if (!validateForm(true)) return;
  
    // Show loader and notify user
    showLoader();
    toast.info("Placing your order...");
  
    try {
      // Prepare cart items
      const cartItems = Object.entries(cart).map(([id, quantity]) => {
        const product = menuItems.find((item) => item.id === parseInt(id));
        return {
          id,
          name: product?.name || `Product ${id}`,
          quantity,
        };
      });
  
      // Log the data being sent to the backend
      const requestBody = {
        amount: total,
        email: formData.email || '',
        name: formData.name || '',
        deliveryMethod: formData.deliveryMethod || 'Delivery',
        items: cartItems,
      };
  
      console.log("Sending data to backend:", requestBody);
  
      // Send the request to the backend
      const response = await fetch('https://firstfltb.com/api3/flutterwave/initialize-payment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      // Parse response
      const data = await response.json();
  
      // Log server response
      console.log("Response from backend:", data);
  
      // Handle successful response
      if (response.ok && data.status === 'success') {
        toast.success("Redirecting to payment gateway...");
        window.location.href = data.data.link;
      } else if (response.ok) {
        // Backend responded but with an error status
        toast.error(data.message || "Failed to initialize payment. Please try again.");
      } else {
        // HTTP error
        toast.error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      // Handle any unexpected errors
      console.error("Error initializing payment:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      // Hide loader regardless of outcome
      hideLoader();
    }
  };
  
  
  

  return (
    <div className="checkout-container">
      {step === 1 && (
        <Details
          formData={formData}
          handleInputChange={handleInputChange}
          handleNext={handleNext}
        />
      )}
      {step === 2 && (
        <DeliveryPaymentContact
          formData={formData}
          handleInputChange={handleInputChange}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}
      {step === 3 && (
        <OrderSummary
          cartItems={cartItems}
          total={total}
          formData={formData}
          handleInputChange={handleInputChange}
        />
      )}

      <div className="checkout-navigation">
        {step > 1 && (
          <div className="nav-item">
            <button className="icon-btn prev-btn" onClick={handleBack}>
              <FaArrowLeft />
            </button>
            <span className="nav-description">Prev</span>
          </div>
        )}
        <div className="nav-item">
          <button className="icon-btn edit-cart-btn" onClick={handleEditCart}>
            <FaShoppingCart />
          </button>
          <span className="nav-description">Edit Cart</span>
        </div>
        {step < 3 && (
          <div className="nav-item">
            <button className="icon-btn next-btn" onClick={handleNext}>
              <FaArrowRight />
            </button>
            <span className="nav-description">Next</span>
          </div>
        )}
        {step === 3 && (
          <div className="nav-item">
            <button className="icon-btn submit-btn" onClick={handleSubmit}>
              <FaCheck />
            </button>
            <span className="nav-description">Place Order (${total.toFixed(2)})</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
