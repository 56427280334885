import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from './Header';
import MobileNavs from './MobileNavs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      {isMobile ? <MobileNavs /> : <Header />}
      <main>{children}</main>
      {/* ToastContainer at the root layout */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: 9999 }} // Ensure it appears on top of all elements
      />
    </div>
  );
};

export default Layout;
