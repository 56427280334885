import React, { useState, useEffect } from 'react';
import { FaStar, FaHeart, FaPaperPlane, FaUtensils } from 'react-icons/fa';
import './Review.css';
import { database, ref, onValue, push, update } from '../firebase';

const Review = () => {
  const [products, setProducts] = useState([]); // Store products from the database
  const [reviews, setReviews] = useState([]); // Store reviews from the database
  const [newReview, setNewReview] = useState('');
  const [rating, setRating] = useState(0);
  const [selectedProductId, setSelectedProductId] = useState('');

  // Fetch products from the database
  useEffect(() => {
    const productsRef = ref(database, 'products');
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedProducts = Object.entries(data).map(([id, details]) => ({
          id,
          ...details,
        }));
        setProducts(loadedProducts);
      }
    });
  }, []);

  // Fetch reviews from the database
  useEffect(() => {
    const reviewsRef = ref(database, 'reviews');
    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedReviews = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setReviews(loadedReviews);
      } else {
        setReviews([]);
      }
    });
  }, []);

  // Handle adding a new review
  const handleAddReview = async () => {
    if (newReview.trim() === '' || rating === 0) {
      alert('Please provide a review and a rating.');
      return;
    }

    const selectedProduct = products.find((product) => product.id === selectedProductId);

    const review = {
      productId: selectedProductId || null,
      productName: selectedProduct?.name || 'Service',
      productImage: selectedProduct?.image || null,
      text: newReview,
      rating,
      likes: {}, // Store likes as an object
      timestamp: Date.now(),
    };

    try {
      const reviewsRef = ref(database, 'reviews');
      await push(reviewsRef, review);
      setNewReview('');
      setRating(0);
      setSelectedProductId('');
      alert('Review submitted successfully!');
    } catch (error) {
      console.error('Error saving review:', error);
      alert('Failed to submit review. Please try again.');
    }
  };

  // Handle liking/unliking a review
  const handleLike = async (reviewId) => {
    const likedReviews = JSON.parse(localStorage.getItem('likedReviews')) || {};

    if (likedReviews[reviewId]) {
      // If already liked, unlike it
      const review = reviews.find((r) => r.id === reviewId);
      const likes = review.likes || {};
      delete likes['localUser'];

      try {
        const reviewRef = ref(database, `reviews/${reviewId}/likes`);
        await update(reviewRef, likes);

        // Update localStorage
        delete likedReviews[reviewId];
        localStorage.setItem('likedReviews', JSON.stringify(likedReviews));
      } catch (error) {
        console.error('Error unliking review:', error);
      }
    } else {
      // If not liked, like it
      const review = reviews.find((r) => r.id === reviewId);
      const likes = review.likes || {};
      likes['localUser'] = true;

      try {
        const reviewRef = ref(database, `reviews/${reviewId}/likes`);
        await update(reviewRef, likes);

        // Update localStorage
        likedReviews[reviewId] = true;
        localStorage.setItem('likedReviews', JSON.stringify(likedReviews));
      } catch (error) {
        console.error('Error liking review:', error);
      }
    }
  };

  return (
    <div className="review-page-container">
      <h1 className="review-header">Leave a Review</h1>
      <h4>Your honest reviews and comments help us improve our service and products.</h4>

      {/* Add Review Section */}
      <div className="add-review-container">
        {/* Product Selector */}
        <select
          className="product-selector"
          value={selectedProductId}
          onChange={(e) => setSelectedProductId(e.target.value)}
        >
          <option value="">Review Our Service</option>
          {products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>

        {/* Textarea for review */}
        <textarea
          className="review-input"
          placeholder="Write your review here..."
          value={newReview}
          onChange={(e) => setNewReview(e.target.value)}
        />

        {/* Rating System */}
        <div className="rating-container">
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              className={`star-icon ${index < rating ? 'active' : ''}`}
              onClick={() => setRating(index + 1)}
            />
          ))}
        </div>

        {/* Submit Button */}
        <button className="submit-review-btn" onClick={handleAddReview}>
          <FaPaperPlane /> Submit Review
        </button>
      </div>

      {/* Reviews Section */}
      <div className="reviews-grid">
        {reviews.map((review) => (
          <div key={review.id} className="review-card">
            <div className="review-content">
              <div className="review-text-section">
                <h3 className="review-product">
                  {review.productId ? (
                    <>
                      <FaUtensils /> {review.productName}
                    </>
                  ) : (
                    'Service'
                  )}
                </h3>
                <p className="review-text">{review.text}</p>

                {/* Image Section */}
                {review.productId && (
                  <div className="review-image-section">
                    <img
                      src={review.productImage || 'https://via.placeholder.com/150'}
                      alt={review.productName}
                      className="review-image"
                    />
                  </div>
                )}

                {/* Star Rating */}
                <div className="review-rating">
                  {[...Array(5)].map((_, i) => (
                    <FaStar key={i} className={`star-icon ${i < review.rating ? 'active' : ''}`} />
                  ))}
                </div>

                {/* Like Button */}
                <div className="review-actions">
                  <button
                    className={`like-btn ${
                      JSON.parse(localStorage.getItem('likedReviews'))?.[review.id] ? 'liked' : ''
                    }`}
                    onClick={() => handleLike(review.id)}
                  >
                    <FaHeart className="like-icon" /> {Object.keys(review.likes || {}).length}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Review;
