import React, { useState, useEffect } from 'react';
import './Hero.css';
import { toast } from 'react-toastify';
import { FaFilter, FaUtensils  } from 'react-icons/fa';
import { menuItems } from '../utils/TempData';
import Modal from '../components/Modal';
import { useAppContext } from '../context/AppWrapper';

const categories = [...new Set(menuItems.map((item) => item.category))];

const Hero = () => {
  const { cart, setCart } = useAppContext();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCategory, setModalCategory] = useState('');

  // Load cart from localStorage on component mount
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }
  }, [setCart]);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleCategoryChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((cat) => cat !== category)
        : [...prev, category]
    );
  };

  const handleAddToCart = (id, name) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart, [id]: (prevCart[id] || 0) + 1 };
      return updatedCart;
    });
    toast.success(`${name} added to cart!`);
  };

  const handleRemoveFromCart = (id, name) => {
    if (cart[id]) {
      setCart((prevCart) => {
        if (prevCart[id] > 1) {
          const updatedCart = { ...prevCart, [id]: prevCart[id] - 1 };
          return updatedCart;
        } else {
          const updatedCart = { ...prevCart };
          delete updatedCart[id];
          return updatedCart;
        }
      });
      toast.info(`${name} quantity updated!`);
    } else {
      toast.info(`You haven't added ${name} yet!`);
    }
  };

  const filteredItems = selectedCategories.length
    ? menuItems.filter((item) => selectedCategories.includes(item.category))
    : menuItems;

  return (
    <div className="hero-container">
      <h2 className="sticky-header">
  <FaUtensils className="icon" /> Our Menu
</h2>


      {/* Filter Button */}
      <div className="filter-container">
        <button
          className="filter-btn"
          onClick={() => setShowFilterOptions((prev) => !prev)}
        >
          <FaFilter /> Filter
        </button>
        {showFilterOptions && (
          <div className="filter-dropdown">
            {categories.map((category) => (
              <label key={category} className="filter-option">
                <input
                  type="checkbox"
                  value={category}
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
                {category}
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Grouped Content */}
      {categories.map((category) => {
        if (!selectedCategories.length || selectedCategories.includes(category)) {
          return (
            <div key={category}>
              <div className="section-header">
                <h3>{category}</h3>
                <button
                  className="see-more-btn"
                  onClick={() => {
                    setModalCategory(category);
                    setModalOpen(true);
                  }}
                >
                  See More
                </button>
              </div>
              <div className="menu-grid">
                {filteredItems
                  .filter((item) => item.category === category)
                  .slice(0, 4) // Display up to 4 items
                  .map((item) => (
                    <div key={item.id} className="menu-item">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="menu-image"
                      />
                      <div className="menu-details">
                        <div className="menu-name">
                          {item.icon} {item.name}
                        </div>
                        <div className="menu-price">${item.price.toFixed(2)}</div>
                        <div className="menu-actions">
                          <button
                            onClick={() =>
                              handleRemoveFromCart(item.id, item.name)
                            }
                          >
                            -
                          </button>
                          <span>{cart[item.id] || 0}</span>
                          <button
                            onClick={() => handleAddToCart(item.id, item.name)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        }
        return null;
      })}

      {/* Modal */}
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <h3>{modalCategory} Menu</h3>
        <div className="menu-grid">
          {menuItems
            .filter((item) => item.category === modalCategory)
            .map((item) => (
              <div key={item.id} className="menu-item">
                <img src={item.image} alt={item.name} className="menu-image" />
                <div className="menu-details">
                  <div className="menu-name">
                    {item.icon} {item.name}
                  </div>
                  <div className="menu-price">${item.price.toFixed(2)}</div>
                  <div className="menu-actions">
                    <button
                      onClick={() => handleRemoveFromCart(item.id, item.name)}
                    >
                      -
                    </button>
                    <span>{cart[item.id] || 0}</span>
                    <button
                      onClick={() => handleAddToCart(item.id, item.name)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default Hero;
