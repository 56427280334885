import React, { createContext, useState, useContext, useEffect } from 'react';
import WelcomeLoader from '../components/WelcomeLoader';
import Loader from '../components/Loader';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const AppWrapper = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [cart, setCart] = useState(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    return savedCart || {};
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoad(false);
    }, 500); // 3 seconds for welcome loader

    return () => clearTimeout(timer);
  }, []);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  return (
    <AppContext.Provider
      value={{
        loading,
        showLoader,
        hideLoader,
        cart,
        setCart,
      }}
    >
      {initialLoad && <WelcomeLoader />}
      {loading && !initialLoad && <Loader />}
      {!initialLoad && !loading && children}
    </AppContext.Provider>
  );
};

export default AppWrapper;
