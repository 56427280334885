import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { CheckCircle, AlertCircle, Shield, Key } from 'lucide-react';
import './TwoFactorAuth.css';

const TwoFactorAuth = () => {
  const { currentUser } = useAuth();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState(''); // "google" or "email"
  const [setupStep, setSetupStep] = useState(false); // True during setup
  const [qrCode, setQrCode] = useState(''); // Holds QR Code URL
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Error message for display

  // Fetch current 2FA status on component mount
  useEffect(() => {
    const fetchStatus = async () => {
      if (!currentUser) return;

      setLoading(true);
      setErrorMessage('');
      try {
        const { data } = await axios.post(
          'https://firstfltb.com/api3/2fa/getTwoFactorStatus.php',
          { userId: currentUser.uid }
        );
        setTwoFactorEnabled(data.enabled);
        setTwoFactorType(data.type || '');
      } catch (error) {
        setErrorMessage('Failed to fetch your 2FA status. Please try again.');
        console.error('Error fetching 2FA status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [currentUser]);

  // Initiate 2FA setup
  const initiateTwoFactorSetup = async (type) => {
    if (!currentUser) return;
  
    setLoading(true); // Show loading spinner
    setErrorMessage(''); // Clear previous errors
    try {
      const requestBody = {
        userId: currentUser.uid,
        type,
      };
  
      // Include user's email if needed
      if (type === 'google' || type === 'email') {
        requestBody.email = currentUser.email;
      }
  
      // Call the backend to initiate 2FA
      const { data } = await axios.post(
        'https://firstfltb.com/api3/2fa/initiateTwoFactor.php',
        requestBody
      );
  
      // Handle Google Authenticator response
      if (type === 'google' && data.qrCodeUrl) {
        setQrCode(data.qrCodeUrl); // Set QR code URL for display
      } else if (type === 'email') {
        alert('Verification code sent to your email.');
      }
  
      setTwoFactorType(type); // Update the 2FA type
      setSetupStep(true); // Move to the setup step
    } catch (error) {
      // Handle errors gracefully
      setErrorMessage('Failed to initiate 2FA setup. Please try again.');
      console.error('Error initiating 2FA setup:', error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };
  
  

  // Verify the token to complete setup
  const verifyTwoFactorSetup = async () => {
    if (!verificationCode || !currentUser) {
      setErrorMessage('Please enter a verification code.');
      return;
    }

    setLoading(true);
    setErrorMessage('');
    try {
      const { data } = await axios.post(
        'https://firstfltb.com/api3/2fa/verifyTwoFactor.php',
        { userId: currentUser.uid, type: twoFactorType, token: verificationCode }
      );

      if (data.success) {
        alert('Two-factor authentication enabled!');
        setTwoFactorEnabled(true);
        setSetupStep(false);
        setVerificationCode('');
      } else {
        setErrorMessage('Invalid verification code. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to verify 2FA setup. Please try again.');
      console.error('Error verifying 2FA:', error);
    } finally {
      setLoading(false);
    }
  };

  // Disable 2FA
  const disableTwoFactor = async () => {
    if (!currentUser || !verificationCode) {
      setErrorMessage('Enter your current verification code to disable 2FA.');
      return;
    }

    setLoading(true);
    setErrorMessage('');
    try {
      const { data } = await axios.post(
        'https://firstfltb.com/api3/2fa/removeTwoFactor.php',
        { userId: currentUser.uid, token: verificationCode }
      );

      if (data.success) {
        alert('Two-factor authentication disabled!');
        setTwoFactorEnabled(false);
        setTwoFactorType('');
        setVerificationCode('');
      } else {
        setErrorMessage('Failed to disable 2FA. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while disabling 2FA. Please try again.');
      console.error('Error disabling 2FA:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="two-factor-auth-container">
      <h2>
        <Shield /> Two-Factor Authentication
      </h2>

      {loading && <p className="loading">Loading...</p>}

      {errorMessage && (
        <div className="error-message">
          <AlertCircle /> {errorMessage}
        </div>
      )}

      {!loading && !twoFactorEnabled ? (
        <div>
          <p className="status-message">
            <AlertCircle /> Your account is <span className="not-secured">not secured</span>. Enable Two-Factor Authentication for added security.
          </p>
          <div className="setup-options">
            <button
              onClick={() => initiateTwoFactorSetup('google')}
              className="setup-btn"
            >
              <Key /> Setup with Google Authenticator
            </button>
            <button
              onClick={() => initiateTwoFactorSetup('email')}
              className="setup-btn"
            >
              <Key /> Setup with Email
            </button>
          </div>
        </div>
      ) : null}

      {twoFactorEnabled && (
        <div>
          <p className="status-message">
            <CheckCircle /> Your account is secured with {twoFactorType === 'google' ? 'Google Authenticator' : 'Email'}.
          </p>
          <div className="remove-section">
            <p>To remove 2FA, enter your current verification token:</p>
            <input
              type="text"
              placeholder="Enter verification token"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button onClick={disableTwoFactor} className="remove-btn">
              <AlertCircle /> Disable 2FA
            </button>
          </div>
        </div>
      )}

{setupStep && (
  <div className="setup-step">
    {twoFactorType === 'google' && qrCode ? (
      <>
        <p>Scan this QR Code with Google Authenticator:</p>
        <img src={qrCode} alt="Google Authenticator QR Code" className="qr-code" />
      </>
    ) : (
      <p>Enter the code sent to your email to complete setup.</p>
    )}
    <input
      type="text"
      placeholder="Enter verification code"
      value={verificationCode}
      onChange={(e) => setVerificationCode(e.target.value)}
    />
    <button onClick={verifyTwoFactorSetup} className="verify-btn">
      <CheckCircle /> Verify Code
    </button>
  </div>
)}
    </div>
  );
};

export default TwoFactorAuth;
