import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  FaHome,
  FaBoxOpen,
  FaTags,
  FaUserShield,
  FaMoneyCheckAlt,
  FaCog,
  FaSignOutAlt,
  FaStar,
  FaChartLine,
  FaClipboardList,
  FaTasks,
  FaBars,
  FaTimes,
  FaLock,
} from 'react-icons/fa';
import { ref, onValue, database } from '../firebase';
import { useAuth } from '../context/AuthContext';
import './AdminHeader.css';

const AdminHeader = () => {
  const { logout, currentUser } = useAuth(); // Use currentUser to access role
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [counts, setCounts] = useState({ activities: 0, orders: 0, transactions: 0 }); // Unread counts

  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await logout();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error during logout:', error.message);
    } finally {
      setLoggingOut(false);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleRestrictedAccess = () => {
    alert('You need Admin privileges to access this section.');
  };

  const handleLinkClick = (section) => {
    setMenuOpen(false); // Close the menu after clicking a link

    // Reset count for the clicked section
    setCounts((prevCounts) => ({
      ...prevCounts,
      [section]: 0,
    }));
  };

  useEffect(() => {
    const activitiesRef = ref(database, 'adminActivities');
    const ordersRef = ref(database, 'orders');
    const transactionsRef = ref(database, 'transactions');

    const playNotificationSound = () => {
      const audio = new Audio(process.env.PUBLIC_URL + '/notification.mp3');
      audio.play().catch((error) => {
        console.error('Error playing audio:', error.message);
      });
    };
    

    // Listen for new activities
    onValue(activitiesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCounts((prev) => ({ ...prev, activities: Object.keys(data).length }));
        playNotificationSound();
      }
    });

    // Listen for new orders
    onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCounts((prev) => ({ ...prev, orders: Object.keys(data).length }));
        playNotificationSound();
      }
    });

    // Listen for new transactions
    onValue(transactionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCounts((prev) => ({ ...prev, transactions: Object.keys(data).length }));
        playNotificationSound();
      }
    });
  }, []);

  if (!currentUser) return null; // Hide header if not authenticated

  const isAdmin = currentUser.role === 'admin';
  const isCashier = currentUser.role === 'cashier';

  return (
    <header className="admin-header">
      <div className="admin-header-content">
        <div className="admin-header-left">
          <button
            className="hamburger-menu"
            onClick={toggleMenu}
            aria-label="Toggle Menu"
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        <nav className={`admin-nav ${menuOpen ? 'open' : ''}`}>
          <NavLink
            to="/admin/dashboard"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('dashboard')}
          >
            <FaHome /> Dashboard {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/products"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('products')}
          >
            <FaBoxOpen /> Products {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/categories"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('categories')}
          >
            <FaTags /> Categories {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/orders"
            className="admin-nav-link"
            activeClassName="admin-active"
            onClick={() => handleLinkClick('orders')}
          >
            <FaClipboardList /> Orders {counts.orders > 0 && <span className="badge">{counts.orders}</span>}
          </NavLink>
          <NavLink
            to="/admin/users"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('users')}
          >
            <FaUserShield /> Users {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/transactions"
            className="admin-nav-link"
            activeClassName="admin-active"
            onClick={() => handleLinkClick('transactions')}
          >
            <FaMoneyCheckAlt /> Transactions {counts.transactions > 0 && <span className="badge">{counts.transactions}</span>}
          </NavLink>
          <NavLink
            to="/admin/reviews"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('reviews')}
          >
            <FaStar /> Reviews {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/best-sellers"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('bestSellers')}
          >
            <FaChartLine /> Best Sellers {isCashier && <FaLock />}
          </NavLink>
          <NavLink
            to="/admin/activities"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('activities')}
          >
            <FaTasks /> Activities {counts.activities > 0 && <span className="badge">{counts.activities}</span>}
          </NavLink>
          <NavLink
            to="/admin/settings"
            className={`admin-nav-link ${isCashier ? 'restricted' : ''}`}
            activeClassName="admin-active"
            onClick={isCashier ? handleRestrictedAccess : () => handleLinkClick('settings')}
          >
            <FaCog /> Settings {isCashier && <FaLock />}
          </NavLink>
          <button
            className="admin-logout-btn"
            onClick={handleLogout}
            disabled={loggingOut}
          >
            {loggingOut ? (
              <>
                <span className="spinner" /> Logging out...
              </>
            ) : (
              <>
                <FaSignOutAlt /> Logout
              </>
            )}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default AdminHeader;
