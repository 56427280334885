import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaUtensils, FaShoppingCart, FaUser, FaStar, FaCog } from 'react-icons/fa';
import './Header.css';
import { useAppContext } from '../context/AppWrapper';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';

const Header = () => {
  const { cart, showLoader, hideLoader } = useAppContext();
  const { currentUser } = useAuth(); // Get authenticated user info
  const totalItemsInCart = Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);

  const [isVibrating, setIsVibrating] = useState(false);

  useEffect(() => {
    let vibrationTimer;
    let notificationTimer;

    if (totalItemsInCart > 0) {
      // Vibrate the cart icon every 5 minutes
      notificationTimer = setInterval(() => {
        toast.info('You have items in your cart. Don’t forget to checkout!');
      }, 5 * 60 * 1000);

      // Trigger vibration effect for a short duration
      vibrationTimer = setInterval(() => {
        setIsVibrating(true);
        setTimeout(() => {
          setIsVibrating(false);
        }, 1000); // Vibration duration
      }, 5 * 60 * 1000); // Vibrate every 5 minutes
    }

    // Cleanup timers
    return () => {
      clearInterval(vibrationTimer);
      clearInterval(notificationTimer);
    };
  }, [totalItemsInCart]);

  const handleNavigation = (path) => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 500); // Simulate loader delay
  };

  return (
    <header className="header">
      <nav className="desktop-nav">
        <NavLink to="/" className="nav-link menu-link" onClick={() => handleNavigation('/')}>
          <FaUtensils className="nav-icon" />
          <span>Menu</span>
        </NavLink>
        <NavLink
          to="/cart"
          className={`nav-link cart-link ${isVibrating ? 'vibrating' : ''}`}
          onClick={() => handleNavigation('/cart')}
        >
          <FaShoppingCart className="nav-icon" />
          <span>Cart</span>
          {totalItemsInCart > 0 && <span className="cart-count">{totalItemsInCart}</span>}
        </NavLink>
        <NavLink to="/account" className="nav-link account-link" onClick={() => handleNavigation('/account')}>
          <FaUser className="nav-icon" />
          <span>Account</span>
        </NavLink>
        <NavLink
          to={currentUser ? '/settings' : '/review'}
          className="nav-link review-link"
          onClick={() => handleNavigation(currentUser ? '/settings' : '/review')}
        >
          {currentUser ? <FaCog className="nav-icon" /> : <FaStar className="nav-icon" />}
          <span>{currentUser ? 'Settings' : 'Review'}</span>
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
