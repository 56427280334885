import React, { useState, useEffect } from 'react';
import { FaFilter, FaRedo, FaEye, FaStar } from 'react-icons/fa'; // Icons for buttons
import { getDatabase, ref, get } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './OrderHistory.css'; // Custom styling

const OrderHistory = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState('all'); // 'all', 'delivered', 'processing', etc.
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Fetch all orders for the user
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const db = getDatabase();
        const ordersRef = ref(db, `users/${userId}/orders`); // Path to user orders in Firebase
        const snapshot = await get(ordersRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          const orderList = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setOrders(orderList);
          setFilteredOrders(orderList);
        } else {
          setOrders([]);
          setFilteredOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  // Filter orders
  const handleFilter = (filterValue) => {
    setFilter(filterValue);
    if (filterValue === 'all') {
      setFilteredOrders(orders);
    } else {
      const filtered = orders.filter((order) => order.status === filterValue);
      setFilteredOrders(filtered);
    }
  };

  // Reorder an order
  const handleReorder = (order) => {
    console.log('Reordering order:', order);
    // Logic for reordering
    alert(`Reordering order ${order.id}`);
  };

  // Redirect to the review page with order ID
  const handleReview = (orderId) => {
    navigate(`/review?orderId=${orderId}`);
  };

  return (
    <div className="order-history-container">
      <h1>Order History</h1>

      {/* Filter Section */}
      <div className="filter-container">
        <FaFilter />
        <select
          value={filter}
          onChange={(e) => handleFilter(e.target.value)}
          className="filter-select"
        >
          <option value="all">All Orders</option>
          <option value="processing">Processing</option>
          <option value="delivered">Delivered</option>
          <option value="cancelled">Cancelled</option>
        </select>
      </div>

      {/* Orders List */}
      {loading ? (
        <p>Loading orders...</p>
      ) : filteredOrders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <div className="orders-grid">
          {filteredOrders.map((order) => (
            <div key={order.id} className="order-card">
              <div className="order-summary">
                <h4>Order #{order.id}</h4>
                <p><strong>Date:</strong> {order.date}</p>
                <p><strong>Status:</strong> {order.status}</p>
              </div>
              <div className="order-actions">
                <button className="action-btn reorder" onClick={() => handleReorder(order)}>
                  <FaRedo /> Reorder
                </button>
                <button className="action-btn review" onClick={() => handleReview(order.id)}>
                  <FaStar /> Review
                </button>
                <button
                  className="action-btn details"
                  onClick={() => {
                    const updatedOrders = orders.map((o) =>
                      o.id === order.id ? { ...o, expanded: !o.expanded } : o
                    );
                    setOrders(updatedOrders);
                    setFilteredOrders(updatedOrders.filter((o) => o.status === filter || filter === 'all'));
                  }}
                >
                  <FaEye /> {order.expanded ? 'Hide Details' : 'View Details'}
                </button>
              </div>

              {/* Full Details */}
              {order.expanded && (
                <div className="order-details">
                  <p><strong>Items:</strong></p>
                  <ul>
                    {order.items.map((item, index) => (
                      <li key={index}>
                        {item.name} (x{item.quantity}) - ${item.price.toFixed(2)}
                      </li>
                    ))}
                  </ul>
                  <p><strong>Total:</strong> ${order.total.toFixed(2)}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
