import React, { useState, useEffect } from 'react';
import { FaGift, FaPercentage, FaMoneyBillWave } from 'react-icons/fa'; // Icons for vouchers
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatabase, ref, get, update } from 'firebase/database'; // Firebase functions
import './Voucher.css'; // Custom styling

const VoucherPage = ({ userId }) => {
  const [vouchers, setVouchers] = useState([]);
  const [creditBalance, setCreditBalance] = useState(0);

  // Fetch vouchers and user credit balance on mount
  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const db = getDatabase();

        // Fetch vouchers
        const vouchersRef = ref(db, 'vouchers/');
        const vouchersSnapshot = await get(vouchersRef);
        if (vouchersSnapshot.exists()) {
          setVouchers(Object.entries(vouchersSnapshot.val()).map(([key, value]) => ({ id: key, ...value })));
        }

        // Fetch user balance
        const userRef = ref(db, `users/${userId}/balance`);
        const userSnapshot = await get(userRef);
        if (userSnapshot.exists()) {
          setCreditBalance(userSnapshot.val());
        }
      } catch (error) {
        console.error('Error fetching vouchers or balance:', error);
        toast.error('Failed to load vouchers or balance.');
      }
    };

    fetchVouchers();
  }, [userId]);

  // Handle redeem voucher
  const handleRedeem = async (voucher) => {
    try {
      const db = getDatabase();

      // Update user balance
      const newBalance = creditBalance + voucher.value;
      const userRef = ref(db, `users/${userId}`);
      await update(userRef, { balance: newBalance });

      // Mark voucher as redeemed
      const voucherRef = ref(db, `vouchers/${voucher.id}`);
      await update(voucherRef, { redeemed: true });

      // Update local state
      setCreditBalance(newBalance);
      setVouchers((prevVouchers) => prevVouchers.map((v) => (v.id === voucher.id ? { ...v, redeemed: true } : v)));

      toast.success('Voucher redeemed successfully!');
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      toast.error('Failed to redeem voucher.');
    }
  };

  return (
    <div className="voucher-page-container">
      <h1>Your Vouchers</h1>

      {/* Informational Section */}
      <div className="info-container">
        <h3>How the Voucher System Works</h3>
        <ul>
          <li>Vouchers represent discounts, cashback, or gift rewards you can use.</li>
          <li>To redeem a voucher, click the <strong>Redeem Voucher</strong> button next to it.</li>
          <li>Once redeemed, the voucher value is added to your credit balance (₦).</li>
          <li>Redeemed vouchers cannot be reused.</li>
        </ul>
        <p>Start redeeming your vouchers and enjoy the benefits!</p>
      </div>

      {/* Display Credit Balance */}
      <div className="credit-balance-container">
        <h3>Credit Balance</h3>
        <p>₦{creditBalance.toFixed(2)}</p>
      </div>

      {/* Display Vouchers */}
      <div className="vouchers-grid">
        {vouchers.length === 0 ? (
          <p>No vouchers available.</p>
        ) : (
          vouchers.map((voucher) => (
            <div key={voucher.id} className="voucher-card">
              <div className="voucher-icon">
                {voucher.type === 'percentage' ? (
                  <FaPercentage />
                ) : voucher.type === 'cashback' ? (
                  <FaMoneyBillWave />
                ) : (
                  <FaGift />
                )}
              </div>
              <div className="voucher-details">
                <h4>{voucher.title}</h4>
                <p>Value: ₦{voucher.value}</p>
              </div>
              {!voucher.redeemed && (
                <button
                  className="redeem-btn"
                  onClick={() => handleRedeem(voucher)}
                >
                  Redeem Voucher
                </button>
              )}
              {voucher.redeemed && <p className="redeemed-text">Redeemed</p>}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default VoucherPage;
