import React, { useState, useEffect } from "react";
import { database, ref, push, onValue, set, remove, update } from "../../firebase";
import { FaPlus, FaTrash, FaCheckCircle, FaEye } from "react-icons/fa"; // Icons for Create, Delete, Activate, and Preview
import "./AdminAnnouncements.css";

const AdminAnnouncements = () => {
  const [adminNotificationAnnouncements, setAdminNotificationAnnouncements] = useState([]);
  const [adminNotificationShowForm, setAdminNotificationShowForm] = useState(false);
  const [adminNotificationTitle, setAdminNotificationTitle] = useState("");
  const [adminNotificationMessage, setAdminNotificationMessage] = useState("");
  const [adminNotificationBackgroundImage, setAdminNotificationBackgroundImage] = useState(null);
  const [adminNotificationDisplayTimesPerDay, setAdminNotificationDisplayTimesPerDay] = useState(1);
  const [adminNotificationDurationDays, setAdminNotificationDurationDays] = useState(1);
  const [loading, setLoading] = useState(false); // Spinner for buttons
  const [previewAnnouncement, setPreviewAnnouncement] = useState(null); // For previewing an announcement

  // Fetch announcements from Firebase
  useEffect(() => {
    const announcementsRef = ref(database, "announcements");
    onValue(announcementsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedAnnouncements = Object.entries(data).map(([id, details]) => ({
          id,
          ...details,
        }));
        setAdminNotificationAnnouncements(loadedAnnouncements);
      } else {
        setAdminNotificationAnnouncements([]);
      }
    });
  }, []);

  // Upload background image using the backend (Pinata)
  const handleAdminNotificationUploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("https://firstfltb.com/api3/AddProduct.php", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();

      if (data.status === "success") {
        return data.pinataUrl;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // Handle form submission
  const handleAdminNotificationSubmit = async () => {
    if (!adminNotificationTitle && !adminNotificationMessage && !adminNotificationBackgroundImage) {
      alert("Please provide at least a title, message, or background image.");
      return;
    }

    setLoading(true);

    try {
      let imageUrl = null;
      if (adminNotificationBackgroundImage) {
        imageUrl = await handleAdminNotificationUploadImage(adminNotificationBackgroundImage);
      }

      const announcement = {
        id: push(ref(database, "announcements")).key,
        title: adminNotificationTitle,
        message: adminNotificationMessage,
        image: imageUrl,
        displayTimesPerDay: adminNotificationDisplayTimesPerDay,
        durationDays: adminNotificationDurationDays,
        isActive: false, // Default status is inactive
        views: {}, // For tracking views
        createdAt: Date.now(),
      };

      const announcementRef = ref(database, `announcements/${announcement.id}`);
      await set(announcementRef, announcement);

      alert("Announcement added successfully!");
      setAdminNotificationTitle("");
      setAdminNotificationMessage("");
      setAdminNotificationBackgroundImage(null);
      setAdminNotificationDisplayTimesPerDay(1);
      setAdminNotificationDurationDays(1);
      setAdminNotificationShowForm(false);
    } catch (error) {
      console.error("Error adding announcement:", error);
      alert("Failed to add announcement. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting an announcement
  const handleAdminNotificationDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this announcement?")) return;

    setLoading(true);

    try {
      const announcementRef = ref(database, `announcements/${id}`);
      await remove(announcementRef);
      alert("Announcement deleted successfully!");
    } catch (error) {
      console.error("Error deleting announcement:", error);
      alert("Failed to delete announcement. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle activating an announcement
  const handleAdminNotificationActivate = async (id) => {
    setLoading(true);

    try {
      const announcementRef = ref(database, `announcements/${id}`);
      await update(announcementRef, { isActive: true });
      alert("Announcement activated successfully!");
    } catch (error) {
      console.error("Error activating announcement:", error);
      alert("Failed to activate announcement. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Admin-Notification-Container">
      <h2 className="Admin-Notification-Title">Admin Announcements</h2>

      {/* If no announcements exist */}
      {adminNotificationAnnouncements.length === 0 && !adminNotificationShowForm && (
        <p className="Admin-Notification-No-Data">No announcements found.</p>
      )}

      {/* Button to show form */}
      {!adminNotificationShowForm && (
        <button
          className="Admin-Notification-Create-Button"
          onClick={() => setAdminNotificationShowForm(true)}
        >
          <FaPlus /> Create Announcement
        </button>
      )}

      {/* Announcement Form */}
      {adminNotificationShowForm && (
        <div className="Admin-Notification-Form">
          <h3>Create New Announcement</h3>
          <div className="form-row-container">
            <div className="form-row">
              <label>Title:</label>
              <input
                type="text"
                placeholder="Enter title (optional)"
                value={adminNotificationTitle}
                onChange={(e) => setAdminNotificationTitle(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label>Message:</label>
              <textarea
                placeholder="Enter message (optional)"
                value={adminNotificationMessage}
                onChange={(e) => setAdminNotificationMessage(e.target.value)}
              />
            </div>
          </div>

          <div className="form-row-container">
            <div className="form-row">
              <label>Background Image:</label>
              <input
                type="file"
                onChange={(e) => setAdminNotificationBackgroundImage(e.target.files[0])}
              />
            </div>

            <div className="form-row">
              <label>Display Times Per Day:</label>
              <input
                type="number"
                min="1"
                value={adminNotificationDisplayTimesPerDay}
                onChange={(e) => setAdminNotificationDisplayTimesPerDay(e.target.value)}
              />
            </div>

            <div className="form-row">
              <label>Duration (in days):</label>
              <input
                type="number"
                min="1"
                value={adminNotificationDurationDays}
                onChange={(e) => setAdminNotificationDurationDays(e.target.value)}
              />
            </div>
          </div>

          <div className="form-actions">
            <button onClick={handleAdminNotificationSubmit} disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
            <button onClick={() => setAdminNotificationShowForm(false)}>Cancel</button>
          </div>
        </div>
      )}

      {/* Existing Announcements */}
      <div className="Admin-Notification-List">
        <div className="Admin-Notification-Header">
          <span>Title</span>
          <span>Message</span>
          <span>Image</span>
          <span>Days Running</span>
          <span>Actions</span>
        </div>

        {adminNotificationAnnouncements.map((announcement) => (
          <div key={announcement.id} className="Admin-Notification-Item">
            <span>{announcement.title || "No Title"}</span>
            <span>{announcement.message?.slice(0, 50) || "No Message"}</span>
            <span>
              {announcement.image ? (
                <img src={announcement.image} alt={announcement.title} />
              ) : (
                "No Image"
              )}
            </span>
            <span>
              {Math.floor(
                (Date.now() - announcement.createdAt) / (1000 * 60 * 60 * 24)
              )}{" "}
              days
            </span>
            <span className="Admin-Notification-Item-Actions">
              <button onClick={() => setPreviewAnnouncement(announcement)}>
                <FaEye /> Preview
              </button>
              {/* Preview Modal */}
{previewAnnouncement && (
  <div className="Admin-Notification-Preview-Modal">
    <div className="Admin-Notification-Preview-Content">
      <h3>Preview Announcement</h3>
      <div className="Admin-Notification-Preview">
      <h4>{previewAnnouncement.title || "No Title"}</h4>
        {previewAnnouncement.image && (
          <img
            src={previewAnnouncement.image}
            alt={previewAnnouncement.title || "Announcement"}
          />
        )}
        <p>{previewAnnouncement.message || "No Message"}</p>
      </div>
      <button onClick={() => setPreviewAnnouncement(null)}>Close</button>
    </div>
  </div>
)}

              {!announcement.isActive && (
                <button
                  onClick={() => handleAdminNotificationActivate(announcement.id)}
                  disabled={loading}
                >
                  <FaCheckCircle /> Activate
                </button>
              )}
              <button
                onClick={() => handleAdminNotificationDelete(announcement.id)}
                disabled={loading}
              >
                <FaTrash /> Delete
              </button>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminAnnouncements;
