import React, { useState, useEffect } from "react";
import { ref, set, onValue, database } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { FaUser, FaUserShield, FaPlusCircle } from "react-icons/fa";
import "./UsersAdmin.css";

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedTab, setSelectedTab] = useState("users"); // 'users' or 'admins'
  const [newUser, setNewUser] = useState({ name: "", email: "", password: "", role: "" });

  useEffect(() => {
    const usersRef = ref(database, "users");
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const allUsers = Object.values(data);
        const normalUsers = allUsers.filter((user) => !user.role);
        const adminUsers = allUsers.filter((user) => user.role === "admin" || user.role === "cashier");
        setUsers(normalUsers);
        setAdmins(adminUsers);
      }
    });
  }, []);

  const handleAddUser = async () => {
    if (!newUser.name || !newUser.email || !newUser.password || !newUser.role) {
      alert("Please fill all required fields.");
      return;
    }

    try {
      const userId = uuidv4();
      const newUserRef = ref(database, `users/${userId}`);
      await set(newUserRef, {
        ...newUser,
        id: userId,
        registeredAt: new Date().toISOString(),
      });
      alert("User added successfully!");
      setNewUser({ name: "", email: "", password: "", role: "" });
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Failed to add user.");
    }
  };

  const revokeRole = async (userId) => {
    try {
      const userRef = ref(database, `users/${userId}/role`);
      await set(userRef, null); // Remove the role
      alert("Role revoked successfully!");
    } catch (error) {
      console.error("Error revoking role:", error);
      alert("Failed to revoke role.");
    }
  };

  return (
    <div className="users-admin-container">
      {/* Tab Navigation */}
      <div className="users-admin-tabs">
        <button
          className={`tab ${selectedTab === "users" ? "active" : ""}`}
          onClick={() => setSelectedTab("users")}
        >
          Users
        </button>
        <button
          className={`tab ${selectedTab === "admins" ? "active" : ""}`}
          onClick={() => setSelectedTab("admins")}
        >
          Admins
        </button>
      </div>

      {/* Tab Content */}
      <div className="users-admin-content">
        {selectedTab === "users" && (
          <div>
            <h3>Normal Users</h3>
            {users.map((user) => (
              <div key={user.id} className="user-item">
                <FaUser className="user-icon" />
                <div className="user-details">
                  <p>Name: {user.name || "N/A"}</p>
                  <p>Email: {user.email}</p>
                  <p>Balance: ₦{(user.balance || 0).toFixed(2)}</p>
                  <p>
                    Registered At:{" "}
                    {user.registeredAt
                      ? new Date(user.registeredAt).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {selectedTab === "admins" && (
          <div>
            <h3>Admins & Cashiers</h3>
            {admins.map((admin) => (
              <div key={admin.id} className="user-item">
                <FaUserShield
                  className={`user-icon ${
                    admin.role === "cashier" ? "cashier" : "admin"
                  }`}
                />
                <div className="user-details">
                  <p>Full Name: {admin.name || "N/A"}</p>
                  <p>Email: {admin.email}</p>
                  <p>Role: {admin.role === "cashier" ? "Cashier" : "Admin"}</p>
                </div>
                <button
                  className="revoke-btn"
                  onClick={() => revokeRole(admin.id)}
                >
                  Revoke Role
                </button>
              </div>
            ))}

            {/* Add User Form */}
            <div className="add-user-form">
              <h3>Add User</h3>
              <input
                type="text"
                placeholder="Full Name"
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
              />
              <input
                type="email"
                placeholder="Email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              />
              <input
                type="password"
                placeholder="Password"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              />
              <select
                value={newUser.role}
                onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              >
                <option value="">Select Role</option>
                <option value="admin">Admin</option>
                <option value="cashier">Cashier</option>
              </select>
              <button onClick={handleAddUser}>
                <FaPlusCircle /> Add User
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersAdmin;
