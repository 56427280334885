import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Cart from './pages/Cart';
import Favourite from './pages/Favourite';
import Account from './pages/Account';
import Review from './pages/Review';
import Checkout from './pages/Checkout';
import Confirmation from './pages/checkout/Confirmation';
import UserHome from './Dashboard/UserHome';
import UserSettings from './Dashboard/UserSettings';
import TrackOrder from './Dashboard/TrackOrder';
import PrivateRoute from './context/ProtectedRoute';
import AppWrapper from './context/AppWrapper';
import AuthProvider from './context/AuthContext';
import OrderHistory from './Dashboard/OrderHistory';
import VoucherPage from './Dashboard/VoucherPage';
import AdminRoutes from './Admin/AdminRoutes';
import { registerServiceWorkerAndSaveSubscription } from './serviceWorkerRegister'; // Correct import

function App() {
  React.useEffect(() => {
    registerServiceWorkerAndSaveSubscription(); // Correct function call
  }, []);

  return (
    <AuthProvider>
      <AppWrapper>
        <Router>
          <Routes>
            {/* Admin Routes */}
            <Route path="/admin/*" element={<AdminRoutes />} />

            {/* Main App with Layout */}
            <Route
              path="/*"
              element={
                <Layout>
                  <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/favourite" element={<Favourite />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/review" element={<Review />} />
                    <Route path="/confirmation" element={<Confirmation />} />

                    {/* Authenticated Routes */}
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <UserHome />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <PrivateRoute>
                          <UserSettings />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/track-order"
                      element={
                        <PrivateRoute>
                          <TrackOrder />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/order-history"
                      element={
                        <PrivateRoute>
                          <OrderHistory />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/vouchers"
                      element={
                        <PrivateRoute>
                          <VoucherPage />
                        </PrivateRoute>
                      }
                    />
                  </Routes>
                </Layout>
              }
            />
          </Routes>
        </Router>
      </AppWrapper>
    </AuthProvider>
  );
}

export default App;
