import React from 'react';
import { FaTruck, FaHome, FaCreditCard, FaMoneyBillWave, FaPhone, FaSms, FaWhatsapp } from 'react-icons/fa';
import './DeliveryPaymentContact.css';

const DeliveryPaymentContact = ({ formData, handleInputChange }) => {
  return (
    <div className="delivery-payment-contact-section">
      {/* Delivery Method */}
      <section id="delivery-method">
        <h2>Delivery Method</h2>
        <p className="description">Choose how you'd like to receive your order:</p>
        <div className="radio-group">
          <label className="radio-option">
            <FaTruck className="radio-icon" />
            <input
              type="radio"
              name="deliveryMethod"
              value="Pick Up"
              checked={formData.deliveryMethod === 'Pick Up'}
              onChange={handleInputChange}
            />
            Pick Up
          </label>
          <label className="radio-option">
            <FaHome className="radio-icon" />
            <input
              type="radio"
              name="deliveryMethod"
              value="Delivery"
              checked={formData.deliveryMethod === 'Delivery'}
              onChange={handleInputChange}
            />
            Delivery
          </label>
        </div>
      </section>

      {/* Payment Method */}
      <section id="payment-method">
        <h2>Payment Method</h2>
        <p className="description">Select your preferred payment option:</p>
        <div className="radio-group">
          <label className="radio-option">
            <FaCreditCard className="radio-icon" />
            <input
              type="radio"
              name="paymentMethod"
              value="Card"
              checked={formData.paymentMethod === 'Card'}
              onChange={handleInputChange}
            />
            Card
          </label>
          <label className="radio-option">
            <FaMoneyBillWave className="radio-icon" />
            <input
              type="radio"
              name="paymentMethod"
              value="Bank Transfer"
              checked={formData.paymentMethod === 'Bank Transfer'}
              onChange={handleInputChange}
            />
            Bank Transfer
          </label>
        </div>
      </section>

      {/* Contact Method */}
      <section id="contact-method">
        <h2>Contact Method</h2>
        <p className="description">Choose how we can reach you:</p>
        <div className="radio-group">
          <label className="radio-option">
            <FaPhone className="radio-icon" />
            <input
              type="radio"
              name="contactMethod"
              value="Phone Call"
              checked={formData.contactMethod === 'Phone Call'}
              onChange={handleInputChange}
            />
            Phone Call
          </label>
          <label className="radio-option">
            <FaSms className="radio-icon" />
            <input
              type="radio"
              name="contactMethod"
              value="SMS"
              checked={formData.contactMethod === 'SMS'}
              onChange={handleInputChange}
            />
            SMS
          </label>
          <label className="radio-option">
            <FaWhatsapp className="radio-icon" />
            <input
              type="radio"
              name="contactMethod"
              value="Whatsapp"
              checked={formData.contactMethod === 'Whatsapp'}
              onChange={handleInputChange}
            />
            Whatsapp
          </label>
        </div>
      </section>
    </div>
  );
};

export default DeliveryPaymentContact;
