import React, { useState, useEffect } from "react";
import { FaPlus, FaPaperPlane } from "react-icons/fa";
import "./AdminNotifications.css";

const AdminNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch existing notifications
  useEffect(() => {
    fetch("https://firstfltb.com/api3/fetchNotifications.php")
      .then((res) => res.json())
      .then((data) => {
        console.log("Fetched notifications:", data.notifications);
        // Convert object to an array
        const notificationsArray = data.notifications
          ? Object.entries(data.notifications).map(([id, notif]) => ({
              id, // Add the unique key
              ...notif,
            }))
          : [];
        setNotifications(notificationsArray);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setNotifications([]); // Fallback to empty array
      });
  }, []);

  // Handle notification submission
  const handleNotificationSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("message", message);
    if (image) formData.append("file", image);

    fetch("https://firstfltb.com/api3/AddNotification.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          alert("Notification added successfully!");
          setTitle("");
          setMessage("");
          setImage(null);
          setShowForm(false);
          window.location.reload(); // Reload to fetch updated notifications
        } else {
          alert("Failed to add notification: " + data.message);
        }
      })
      .catch((error) => {
        alert("Failed to add notification");
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  // Handle sending notification manually
  const handleSendNotification = async (notification) => {
    const userConfirmed = window.confirm("Are you sure you want to send this notification?");
    if (!userConfirmed) return;
  
    try {
      const response = await fetch("https://firstfltb.com/api3/sendNotification.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: notification.title || "Default Title",
          message: notification.message || "Default Message",
          icon: notification.icon || "https://gateway.pinata.cloud/ipfs/QmXdShTWYAUCMNFYtYGYSgSpkAVb9KhJ2ibzgQf42NMLVJ",
          url: notification.url || "https://khadzburger.com",
        }),
      });
  
      const data = await response.json();
      console.log("Notification response data:", data);
  
      if (data.status === "success") {
        alert(`Notification sent successfully to ${data.successCount} subscribers!`);
      } else {
        const errorMessages = data.errors.map(
          (error, index) =>
            `Error ${index + 1}: ${error.reason}\nEndpoint: ${error.endpoint}`
        );
        alert(
          `Failed to send some notifications:\n\n${errorMessages.join(
            "\n\n"
          )}\n\nSuccess count: ${data.successCount}`
        );
        console.log("Full log:", data.log);
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Failed to send notification.");
    }
  };
  

  
  

  return (
    <div className="admin-notifications-container">
      <h2 className="notifications-header">Admin Notifications</h2>

      {/* Create Notification Button */}
      {!showForm && (
        <button
          className="create-notification-btn"
          onClick={() => setShowForm(true)}
        >
          <FaPlus /> Create Notification
        </button>
      )}

      {/* Notification Form */}
      {showForm && (
        <div className="notification-form-container">
          <h3>Create New Notification</h3>
          <div className="form-row">
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter notification title"
            />
          </div>
          <div className="form-row">
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter notification message"
            />
          </div>
          <div className="form-row">
            <label>Image (optional):</label>
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
          </div>
          <div className="form-actions">
            <button onClick={handleNotificationSubmit} disabled={loading}>
              {loading ? "Submitting..." : <><FaPaperPlane /> Submit</>}
            </button>
            <button onClick={() => setShowForm(false)}>Cancel</button>
          </div>
        </div>
      )}

      {/* Existing Notifications */}
      {!showForm && (
        <>
          <h3 className="notifications-subheader">Existing Notifications</h3>
          <div className="notifications-list">
            <div className="notifications-header-row">
              <span>Title</span>
              <span>Message</span>
              <span>Image</span>
              <span>Actions</span>
            </div>
            {notifications.map((notif, index) => (
              <div key={notif.id} className="notifications-item">
                <span>{notif.title}</span>
                <span>{notif.message?.slice(0, 50)}...</span>
                <span>
                  {notif.image ? (
                    <img src={notif.image} alt="Notification" />
                  ) : (
                    "No Image"
                  )}
                </span>
                <span className="action-buttons">
                  <button
                    className="send-notification-btn"
                    onClick={() => handleSendNotification(notif)}
                  >
                    Send
                  </button>
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminNotifications;
