// src/components/WelcomeLoader.jsx
import React from 'react';
import './WelcomeLoader.css';
import DeveloperFooter from './DeveloperFooter';

const WelcomeLoader = () => {
  return (
    <div className="welcome-loader">
      <div className="spinner"></div>
      <p className="app-version">Version 1.001</p>
      <DeveloperFooter />
    </div>
  );
};

export default WelcomeLoader;
