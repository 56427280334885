import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle, FaUser, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useAppContext } from '../../context/AppWrapper';
import './Confirmation.css';

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCart } = useAppContext(); // Access the setCart function to clear cart in AppContext
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [txRef, setTxRef] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const isTestMode = process.env.REACT_APP_FLW_ENV === 'test'; // Detect test mode from environment variable

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let txStatus = queryParams.get('status');
    const txMessage = queryParams.get('message');
    const transactionRef = queryParams.get('tx_ref');

    if (isTestMode) {
      txStatus = 'successful'; // Override status to successful in test mode
    }

    setStatus(txStatus);
    setMessage(txMessage || 'Transaction processed');
    setTxRef(transactionRef);

    if (txStatus === 'successful') {
      const randomTrackingNumber = Math.floor(100000 + Math.random() * 900000).toString(); // Generate a 6-digit tracking number
      setTrackingNumber(randomTrackingNumber);

      // Clear local storage and AppContext cart
      localStorage.removeItem('cart');
      localStorage.removeItem('formData');
      setCart({}); // Clear the cart in AppContext

      toast.success('Payment successful! Your tracking number is ready.');
    } else {
      toast.error('Payment failed. Please try again.');
    }
  }, [location, isTestMode, setCart]);

  const handleNavigateToAccount = () => {
    navigate('/account?form=signup');
  };

  const handleRetry = () => {
    navigate('/checkout');
  };

  const handleCopyTrackingNumber = () => {
    navigator.clipboard.writeText(trackingNumber);
    toast.info('Tracking number copied to clipboard!');
  };

  return (
    <div className="confirmation-container">
      <div className="confirmation-content">
        {status === 'successful' ? (
          <div className="success-message">
            <FaCheckCircle className="success-icon" />
            <h1>Payment Successful!</h1>
            <p>
              Transaction Reference: <strong>{txRef}</strong>
            </p>
            <p>
              Tracking Number:{' '}
              <strong onClick={handleCopyTrackingNumber} style={{ cursor: 'pointer', color: '#007bff' }}>
                {trackingNumber} <FaCopy />
              </strong>
            </p>
            <p>Estimated Delivery: 20-35 min</p>
            <button className="account-button" onClick={handleNavigateToAccount}>
              <FaUser /> Create an Account to Track Your Order
            </button>
          </div>
        ) : (
          <div className="error-message">
            <FaTimesCircle className="error-icon" />
            <h1>Payment Failed</h1>
            <p>
              Transaction Reference: <strong>{txRef || 'N/A'}</strong>
            </p>
            <p>{message || 'An error occurred. Please try again.'}</p>
            <button onClick={handleRetry} className="retry-button">
              Retry Payment
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
