import React, { useEffect, useState } from 'react';
import { ref, onValue, database } from '../firebase';
import './AdminActivities.css';

const AdminActivities = () => {
  const [activities, setActivities] = useState([]);

  // Fetch activities from Firebase
  useEffect(() => {
    const activitiesRef = ref(database, 'adminActivities');
    onValue(activitiesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Convert object to array and sort by timestamp (latest first)
        const formattedData = Object.values(data).sort((a, b) => b.timestamp - a.timestamp);
        setActivities(formattedData);
      } else {
        setActivities([]); // If no data is found, ensure activities is an empty array
      }
    });
  }, []);

  return (
    <div className="admin-activities">
      <h2>Admin Activities</h2>

      {activities.length === 0 ? (
        <p className="no-activities">No activities found.</p>
      ) : (
        <>
          {/* Desktop Table */}
          <table className="activities-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, index) => (
                <tr key={index}>
                  <td>{activity.name || activity.email}</td>
                  <td>{activity.action}</td>
                  <td>{new Date(activity.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Mobile List */}
          <div className="activities-list">
            {activities.map((activity, index) => (
              <div key={index} className="activity-card">
                <h3>{activity.name || activity.email}</h3>
                <p><strong>Action:</strong> {activity.action}</p>
                <p><strong>Time:</strong> {new Date(activity.timestamp).toLocaleString()}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminActivities;
