import React from 'react';
import {
  FaBox,
  FaTruck,
  FaUser,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';
import './OrderSummary.css';

const OrderSummary = ({ cartItems, total, formData, handleInputChange, invokeLoader }) => {
  return (
    <div className="order-summary-section">
      <h2>Order Summary</h2>

      {/* Cart Items Section */}
      <div className="summary-section">
        <h3>
          <FaBox className="summary-icon" /> Items
        </h3>
        <div className="cart-grid">
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img src={item.image} alt={item.name} className="cart-item-image" />
              <div>
                <h4>{item.name}</h4>
                <p>Quantity: {item.quantity}</p>
                <p>${(item.price * item.quantity).toFixed(2)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Delivery and Contact Details */}
      <div className="summary-section">
        <h3>
          <FaTruck className="summary-icon" /> Delivery & Contact
        </h3>
        <div className="contact-item">
          <div className="contact-icon">
            <FaUser style={{ color: '#007bff' }} />
          </div>
          <div className="contact-text">
            <strong>Name:</strong> {formData.name}
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-icon">
            <FaPhone style={{ color: '#28a745' }} />
          </div>
          <div className="contact-text">
            <strong>Phone:</strong> {formData.phone}
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-icon">
            <FaEnvelope style={{ color: '#ff5722' }} />
          </div>
          <div className="contact-text">
            <strong>Email:</strong> {formData.email || 'Not provided'}
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-icon">
            <FaMapMarkerAlt style={{ color: '#ff9800' }} />
          </div>
          <div className="contact-text">
            <strong>Address:</strong> {formData.address}, {formData.city},{' '}
            {formData.state}
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-icon">
            <FaTruck style={{ color: '#795548' }} />
          </div>
          <div className="contact-text">
            <strong>Delivery Method:</strong> {formData.deliveryMethod}
          </div>
        </div>
      </div>

      {/* Terms and Conditions */}
      <div className="terms-section">
        <h3>Terms and Conditions</h3>
        <div className="terms-container">
          <input
            type="checkbox"
            name="agreeToTerms"
            checked={formData.agreeToTerms}
            onChange={handleInputChange}
          />
          <p>
            By placing this order, I agree to the terms and conditions of the
            service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
