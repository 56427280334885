import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/AppWrapper';
import { menuItems } from '../utils/TempData';
import { FaTrash, FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import './Cart.css';

const Cart = () => {
  const { cart, setCart, showLoader, hideLoader } = useAppContext();
  const navigate = useNavigate();

  // Load cart from localStorage on component mount
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }
  }, [setCart]);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleAddToCart = (id) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart, [id]: (prevCart[id] || 0) + 1 };
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save to localStorage
      return updatedCart;
    });
    toast.success('Item added to cart!');
  };

  const handleRemoveFromCart = (id) => {
    setCart((prevCart) => {
      if (prevCart[id] > 1) {
        const updatedCart = { ...prevCart, [id]: prevCart[id] - 1 };
        localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save to localStorage
        toast.info('Item quantity reduced!');
        return updatedCart;
      } else {
        const updatedCart = { ...prevCart };
        delete updatedCart[id];
        localStorage.setItem('cart', JSON.stringify(updatedCart)); // Save to localStorage
        toast.warn('Item removed from cart!');
        return updatedCart;
      }
    });
  };

  const handleEmptyCart = () => {
    setCart({});
    localStorage.removeItem('cart'); // Clear localStorage
    toast.warn('Cart emptied!');
  };

  const handleCheckout = () => {
    if (Object.keys(cart).length === 0) {
      toast.error('Your cart is empty!');
      return;
    }

    showLoader(); // Show loader before navigating

    setTimeout(() => {
      navigate('/checkout');
      hideLoader(); // Stop loader after navigation
    }, 500); // Simulate loader delay
  };

  // Convert the cart object to an array of items
  const cartItems = Object.keys(cart).map((id) => {
    const item = menuItems.find((menuItem) => menuItem.id === parseInt(id));
    return { ...item, quantity: cart[id] };
  });

  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const getSuggestions = () => {
    const cartIds = Object.keys(cart).map((id) => parseInt(id));
    const remainingItems = menuItems.filter((item) => !cartIds.includes(item.id));
    return remainingItems.slice(0, 4); // Show up to 4 suggestions
  };

  const suggestions = getSuggestions();

  return (
    <div className="cart-container">
      <h1>Your Cart</h1>
      {cartItems.length > 0 ? (
        <>
          <ul className="cart-items">
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <img src={item.image} alt={item.name} className="cart-item-image" />
                <div className="cart-item-details">
                  <h4>{item.name}</h4>
                  <p>${item.price.toFixed(2)}</p>
                </div>
                <div className="cart-item-actions">
                  <button
                    className="cart-btn red"
                    onClick={() => handleRemoveFromCart(item.id)}
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    className="cart-btn green"
                    onClick={() => handleAddToCart(item.id)}
                  >
                    +
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="empty-cart">
          <p>Your cart is empty! Add items from the menu to get started.</p>
        </div>
      )}
      {suggestions.length > 0 && (
        <div className="suggestions-container">
          <h3>Suggestions</h3>
          <div className="suggestion-grid">
            {suggestions.map((item) => (
              <div key={item.id} className="suggestion-item">
                <img src={item.image} alt={item.name} className="suggestion-image" />
                <div className="suggestion-details">
                  <h4>{item.name}</h4>
                  <p>${item.price.toFixed(2)}</p>
                  <button className="add-suggestion-btn" onClick={() => handleAddToCart(item.id)}>
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="cart-footer">
        <h3>Total: ${total.toFixed(2)}</h3>
        <div className="cart-actions">
          <button onClick={handleEmptyCart} className="empty-cart-btn">
            <FaTrash /> Empty Cart
          </button>
          <button onClick={handleCheckout} className="checkout-btn">
            <FaShoppingCart /> Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
