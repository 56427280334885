import React, { useState } from 'react';
import { FaCreditCard, FaBell, FaLock, FaBullhorn, FaComments, FaDatabase } from 'react-icons/fa'; // Matching icons
import './AdminSettings.css';
import PaymentGateway from './AdminSettings/PaymentGateway'; // Import specific setting components
import AdminNotifications from './AdminSettings/AdminNotifications.jsx';
import TwoFactorAuth from './AdminSettings/TwoFactorAuth';
import Announcements from './AdminSettings/AdminAnnouncements';
import LiveChat from './AdminSettings/LiveChat';
import DatabaseBackup from './AdminSettings/DatabaseBackup';

const AdminSettings = () => {
  const [selectedSettingModal, setSelectedSettingModal] = useState(null); // Scoped modal variable

  const settingsOptions = [
    {
      id: 'payment-gateway',
      title: 'Payment Gateway',
      icon: <FaCreditCard />,
      description: 'Manage and configure payment gateways',
      component: <PaymentGateway />,
    },
    {
      id: 'notifications',
      title: 'Notifications',
      icon: <FaBell />,
      description: 'Set up notification preferences',
      component: <AdminNotifications />,
    },
    {
      id: '2fa',
      title: '2FA Settings',
      icon: <FaLock />,
      description: 'Enable or disable two-factor authentication',
      component: <TwoFactorAuth />,
    },
    {
      id: 'announcements',
      title: 'Announcements',
      icon: <FaBullhorn />,
      description: 'Manage announcements for users',
      component: <Announcements />,
    },
    {
      id: 'live-chat',
      title: 'Live Chat',
      icon: <FaComments />,
      description: 'Configure live chat settings',
      component: <LiveChat />,
    },
    {
      id: 'database-backup',
      title: 'Database Backup',
      icon: <FaDatabase />,
      description: 'Manage database backup and restore',
      component: <DatabaseBackup />,
    },
  ];

  const handleCloseAdminSettingsModal = () => {
    setSelectedSettingModal(null);
  };

  return (
    <div className="admin-settings-container">
      <h2 className="settings-title">Admin Settings</h2>
      <div className="settings-grid">
        {settingsOptions.map((option) => (
          <div
            key={option.id}
            className="settings-card"
            onClick={() => setSelectedSettingModal(option)}
          >
            <div className="settings-card-icon">{option.icon}</div>
            <div className="settings-card-content">
              <h3>{option.title}</h3>
              <p>{option.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedSettingModal && (
        <div className="admin-settings-modal">
          <div className="admin-settings-modal-content">
            <button
              className="admin-settings-close-modal-btn"
              onClick={handleCloseAdminSettingsModal}
            >
              Close
            </button>
            <h3>{selectedSettingModal.title}</h3>
            <div className="admin-settings-modal-body">
              {/* Render the selected component */}
              {selectedSettingModal.component}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSettings;
