import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { MessageCircle } from 'lucide-react';
import FloatingChat from './FloatingChat'; // Updated Floating Chat Component
import './AdminChatWidget.css';

const AdminChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false); // Toggle widget visibility
  const [users, setUsers] = useState([]); // List of users in the database
  const [floatingChats, setFloatingChats] = useState([]); // List of floating chat boxes

  const db = getDatabase();

  useEffect(() => {
    const usersRef = ref(db, 'users');

    // Fetch users
    const unsubscribeUsers = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const userList = Object.keys(data).map((key) => ({
          id: key,
          name: data[key]?.name || 'Unknown User',
          icon: data[key]?.icon || '👤',
          color: data[key]?.color || '#ccc',
        }));
        setUsers(userList);
      } else {
        setUsers([]); // Clear users if no data exists
      }
    });

    return () => {
      unsubscribeUsers(); // Clean up Firebase listener
    };
  }, [db]);

  // Open a floating chat box
  const openFloatingChat = (user) => {
    if (!floatingChats.some((chat) => chat.id === user.id)) {
      setFloatingChats((prev) => [...prev, user]);
    }
  };

  // Close a floating chat box
  const closeFloatingChat = (userId) => {
    setFloatingChats((prev) => prev.filter((chat) => chat.id !== userId));
  };

  return (
    <div className="admin-chat-widget">
      {/* Floating Chat Icon */}
      {!isOpen && (
        <div className="chat-icon" onClick={() => setIsOpen(true)}>
          <MessageCircle />
        </div>
      )}

      {/* User List in Main Widget */}
      {isOpen && (
        <div className="chat-container">
          <div className="chat-header">
            <h3>Admin Chat</h3>
          </div>
          <div className="chat-body">
            <h4>Users</h4>
            <ul>
              {users.map((user) => (
                <li
                  key={user.id}
                  onClick={() => openFloatingChat(user)}
                  className="user-item"
                >
                  <div
                    className="user-icon"
                    style={{ background: user.color }}
                  >
                    {user.icon}
                  </div>
                  <span>{user.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {/* Floating Chats */}
      {floatingChats.map((chat, index) => (
        <FloatingChat
          key={chat.id}
          user={chat}
          index={index}
          onClose={() => closeFloatingChat(chat.id)}
        />
      ))}
    </div>
  );
};

export default AdminChatWidget;
