import React, { useState, useEffect } from 'react';
import { getDatabase, ref, push, onValue } from 'firebase/database';
import { X, Zap } from 'lucide-react';
import './FloatingChat.css';

const FloatingChat = ({ user, onClose, index }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const db = getDatabase();

  useEffect(() => {
    const messagesRef = ref(db, `chats/${user.id}/messages`);

    // Fetch messages for this chat
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val() || [];
      setMessages(Object.values(data));
    });
  }, [user.id, db]);

  // Handle sending a message
  const sendMessage = () => {
    if (!newMessage.trim()) return;

    const messagesRef = ref(db, `chats/${user.id}/messages`);
    push(messagesRef, {
      sender: 'admin',
      text: newMessage,
      timestamp: Date.now(),
    });

    setNewMessage(''); // Clear input
  };

  // Send a buzz
  const sendBuzz = () => {
    const buzzRef = ref(db, `chats/${user.id}/buzz`);
    buzzRef.set(true);
    setTimeout(() => buzzRef.set(false), 1000); // Reset buzz after 1 second
  };

  return (
    <div
      className="floating-chat"
      style={{
        right: `${20 + index * 320}px`, // Dynamically position each chat box
        bottom: '20px',
      }}
    >
      <div className="chat-header">
        <span>{user.name}</span>
        <div className="chat-actions">
          <button onClick={sendBuzz}>
            <Zap />
          </button>
          <button onClick={onClose}>
            <X />
          </button>
        </div>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.sender === 'admin' ? 'admin' : 'user'}`}
          >
            {msg.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default FloatingChat;
