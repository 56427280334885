import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  auth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  updatePassword,
} from '../firebase';
import { useAppContext } from './AppWrapper';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const { showLoader, hideLoader } = useAppContext();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user || null);
      hideLoader(); // Stop the loader after auth state is resolved
    });

    return () => unsubscribe(); // Cleanup subscription
  }, [hideLoader]);

  const logout = async () => {
    try {
      showLoader();
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error during logout:', error.message);
    } finally {
      hideLoader(); // Ensure the loader stops
    }
  };

  const updateUserProfile = async (profileData) => {
    if (!currentUser) {
      throw new Error('No authenticated user to update.');
    }

    try {
      showLoader();
      await updateProfile(currentUser, {
        displayName: profileData.fullName,
        phoneNumber: profileData.mobileNumber,
      });

      // Update currentUser locally to reflect the changes immediately
      setCurrentUser({ ...currentUser, displayName: profileData.fullName });
    } catch (error) {
      console.error('Error updating profile:', error.message);
      throw error;
    } finally {
      hideLoader();
    }
  };

  const updateUserPassword = async (newPassword) => {
    if (!currentUser) {
      throw new Error('No authenticated user to update.');
    }

    try {
      showLoader();
      await updatePassword(currentUser, newPassword);
    } catch (error) {
      console.error('Error updating password:', error.message);
      throw error;
    } finally {
      hideLoader();
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, logout, updateUserProfile, updateUserPassword }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
